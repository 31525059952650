import { saveClientBank } from 'controllers/Finance'
import { useEffect, useRef, useState } from 'react'
import { Button, Divider, Modal } from 'react-daisyui'
import toast from 'react-hot-toast'
import { connect } from 'react-redux'

function AddBankModal({
  accountName: exAccountName,
  bankOption,
  addBankModal,
  setAddBankModal,
  setMyBank,
}) {
  const [accountName, setAccountName] = useState('')
  const [accountNo, setAccountNo] = useState('')
  const [bankCode, setBankCode] = useState('')
  const [loading, setLoading] = useState(false)

  const inputName = useRef(null)
  const inputNo = useRef(null)
  const inputBank = useRef(null)

  useEffect(() => {
    if (exAccountName) setAccountName(exAccountName)
  }, [exAccountName])

  const handleNameChange = (e) => {
    setAccountName(e.target.value)
  }

  const handleNoChange = (e) => {
    setAccountNo(e.target.value)
  }

  const handleMethodChange = (e) => {
    setBankCode(e.target.value)
  }

  const handleNameKey = (e) => {
    if (e.key === 'Enter') {
      inputNo.current.focus()
    }
  }

  const handleNoKey = (e) => {
    if (e.key === 'Enter') {
      inputBank.current.focus()
    }
  }

  const handleProcess = async () => {
    if (accountName === '' || accountNo === '' || bankCode === '')
      return toast.error('Data tidak lengkap')
    setLoading(true)
    const reqData = {
      account_name: accountName,
      account_number: accountNo,
      bank_code: bankCode,
    }
    const bankData = await saveClientBank(reqData)

    if (bankData) {
      setAccountName('')
      setAccountNo('')
      setMyBank(bankData)
      handleClose()
    }

    setLoading(false)
  }

  const handleClose = () => {
    setAddBankModal(false)
  }

  return (
    <Modal.Legacy
      open={addBankModal}
      onClickBackdrop={loading ? null : () => handleClose}
      className='bg-base-200'
    >
      <Button
        size='sm'
        color='ghost'
        shape='circle'
        className='absolute right-2 top-2'
        disabled={loading}
        onClick={handleClose}
      >
        x
      </Button>
      <Modal.Header className='text-center'>
        <Divider className='!ml-0'>Tambah Data Rekening</Divider>
      </Modal.Header>
      <Modal.Body>
        <div className='form-control mb-2'>
          {!exAccountName && (
            <div className='bg-base-300 p-3 rounded-md mb-2'>
              <p className='text-error text-sm'>
                "NAMA PENGIRIM" tidak bisa diubah setelah ditambahkan, mohon isi dengan benar!
              </p>
            </div>
          )}
          {/* <label className='label'>Nama Pengguna</label> */}
          <input
            type='text'
            className='input input-bordered'
            placeholder='Nama Pemilik'
            ref={inputName}
            value={accountName}
            readOnly={!!exAccountName}
            onChange={handleNameChange}
            onKeyUp={handleNameKey}
          />
        </div>
        <div className='form-control mb-2'>
          {/* <label className='label'>Kata Sandi</label> */}
          <input
            type='number'
            className='input input-bordered'
            placeholder='Nomor Rekening'
            ref={inputNo}
            value={accountNo}
            onChange={handleNoChange}
            onKeyUp={handleNoKey}
          />
        </div>
        <div className='form-control mb-2'>
          {/* <label className='label'>Kata Sandi</label> */}
          <select
            type='password'
            className='input input-bordered'
            ref={inputBank}
            value={bankCode}
            onChange={handleMethodChange}
          >
            <option value=''>Pilih Metode Pembayaran</option>
            {bankOption.map((e, index) => {
              return (
                <>
                  <optgroup key={`D${index}`}>{e.label}</optgroup>
                  {e.list.map((ed, index2) => {
                    return (
                      <option
                        key={`A${index2}`}
                        value={ed.bank_code}
                        disabled={ed.status !== 'normal'}
                      >
                        {ed.label}
                      </option>
                    )
                  })}
                </>
              )
            })}
          </select>
        </div>
      </Modal.Body>

      <Modal.Actions className='flex-col'>
        <Button
          variant='outline'
          color=''
          fullWidth={true}
          onClick={handleProcess}
          loading={loading}
          disabled={loading}
        >
          TAMBAH DATA
        </Button>
      </Modal.Actions>
    </Modal.Legacy>
  )
}

const mapStateToProps = (state) => ({
  user: state.user.user,
})

const mapDispatchToProps = (dispatch) => ({
  setUser: (e) => dispatch({ type: 'SET_USER', data: e }),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBankModal)
