import { formatBalance } from 'helpers/Common'
import 'moment/locale/id'
import { useState } from 'react'
import Moment from 'react-moment'

const UserBalance = ({ last_login, balance, updateBalance }) => {
  const [loading, setLoading] = useState(false)

  const requestUpdate = async () => {
    setLoading(true)
    await updateBalance()
    setLoading(false)
  }

  return (
    <div className='p-3 bg-base-300 text-primary-content'>
      <div className='flex flex-row justify-between'>
        <h1 className='text-lg'>Saldo</h1>
        <h2 className='text-md'>Masuk Terakhir</h2>
      </div>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-row'>
          <h1 className='text-lg mr-3'>{balance !== null ? formatBalance(balance) : '-'}</h1>
          <div
            className={`flex items-center text-info ${loading && 'spin-animation'}`}
            onClick={requestUpdate}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99'
              />
            </svg>
          </div>
        </div>
        <span className='text-sm text-warning'>
          {last_login ? (
            <Moment locale='id' format='dddd h:mm a'>
              {last_login}
            </Moment>
          ) : (
            '-'
          )}
        </span>
      </div>
    </div>
  )
}

export default UserBalance
