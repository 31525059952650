import { useNavigate } from 'react-router-dom'

const CategoriesList = ({ category, setCategory }) => {
  let navigate = useNavigate()

  const categories = [
    {
      label: 'POPULER',
      type: 'HOT',
    },
    // {
    //   label: 'TOGEL',
    //   type: 'TOGEL',
    // },
    {
      label: 'SLOT',
      type: 'SL',
    },
    // {
    //   label: 'LIVE_GAME',
    //   type: 'LC',
    // },
    {
      label: 'CASINO',
      type: 'LC',
    },
    // {
    //   label: 'SPORTBOOK',
    //   type: 'SB',
    // },
    {
      label: 'LOTTERY',
      type: 'LK',
    },
    // {
    //   label: 'POKER',
    //   type: 'CB',
    // },
  ]

  const handleClick = (e) => {
    setCategory(e)
    if (e.label === 'POPULER') return navigate('/')
    navigate('categori/' + e.label.toLowerCase())
  }

  return (
    <div>
      <div className='overflow-x-auto whitespace-nowrap bg-neutral w-full flex h-14 border-b border-b-base-content cursor-grab'>
        <div className='flex'>
          {categories.map((e, index) => {
            return (
              <div
                key={index}
                onClick={() => handleClick(e)}
                className='flex-shrink-0 flex flex-col items-center justify-center px-5 py-0'
              >
                <div
                  className={`w-4 h-4 ${e.type === category.type ? 'bg-primary-content' : 'bg-gold'}`}
                  style={{
                    WebkitMask: `url(/images/categories/${e.label.toLowerCase()}.svg) no-repeat center`,
                    mask: `url(/images/categories/${e.label.toLowerCase()}.svg) no-repeat center`,
                  }}
                />
                <span
                  className={`block text-xs mt-1 ${e.type === category.type ? 'text-primary-content' : 'text-gold'}`}
                >
                  {e.label.split('_').join(' ')}
                </span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CategoriesList
