import { login } from 'controllers/Auth'
import { useRef, useState } from 'react'
import { Button, Divider, Modal } from 'react-daisyui'
import { connect } from 'react-redux'

function LoginModal({ setUser, loginModal, setLoginModal, setRegisterModal }) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const inputUsername = useRef(null)
  const inputPassword = useRef(null)

  const handleUsernameChange = (e) => {
    setUsername(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleUsernameKey = (e) => {
    if (e.key === 'Enter') {
      inputPassword.current.focus()
    }
  }

  const handlePasswordKey = (e) => {
    if (e.key === 'Enter') {
      inputPassword.current.blur()
      handleLogin()
    }
  }

  const handleForgotPassword = () => {
    alert('Forgot Password clicked!')
  }

  const handleLogin = async () => {
    setLoading(true)
    const reqData = {
      username,
      password,
    }
    const loginData = await login(reqData)

    setPassword('')
    if (loginData) {
      setUser(loginData)
      setLoginModal(false)
    } else {
      inputPassword.current.focus()
    }

    setLoading(false)
  }

  return (
    <Modal.Legacy
      open={loginModal}
      onClickBackdrop={loading ? null : setLoginModal}
      className='bg-base-200'
    >
      <Button
        size='sm'
        color='ghost'
        shape='circle'
        className='absolute right-2 top-2'
        disabled={loading}
        onClick={setLoginModal}
      >
        x
      </Button>
      <Modal.Header className='text-center'>MASUK</Modal.Header>
      <Modal.Body>
        <div className='form-control'>
          <label className='label'>Nama Pengguna</label>
          <input
            type='text'
            className='input input-bordered'
            ref={inputUsername}
            value={username}
            onChange={handleUsernameChange}
            onKeyUp={handleUsernameKey}
          />
        </div>
        <div className='form-control'>
          <label className='label'>Kata Sandi</label>
          <input
            type='password'
            className='input input-bordered'
            ref={inputPassword}
            value={password}
            onChange={handlePasswordChange}
            onKeyUp={handlePasswordKey}
          />
        </div>
        {/* <Button className='btn btn-ghost' onClick={handleForgotPassword}>
          Lupa Kata Sandi? Klik Disini
        </Button> */}
      </Modal.Body>

      <Modal.Actions className='flex-col'>
        <Button
          variant='outline'
          color=''
          fullWidth={true}
          onClick={handleLogin}
          loading={loading}
          disabled={loading}
        >
          MASUK
        </Button>
        <Divider className='!ml-0'>Belum Punya Akun?</Divider>
        <Button
          className='!ml-0'
          color='accent'
          fullWidth={true}
          onClick={setRegisterModal}
          disabled={loading}
        >
          REGISTRASI
        </Button>
      </Modal.Actions>
    </Modal.Legacy>
  )
}

const mapStateToProps = (state) => ({
  user: state.user.user,
})

const mapDispatchToProps = (dispatch) => ({
  setUser: (e) => dispatch({ type: 'SET_USER', data: e }),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal)
