import { request } from 'helpers/Common'
import { isIOS } from 'react-device-detect'

export async function getRecommendationGame() {
  const reqStatus = await request('/game/recommendation', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data.data
}

export async function getRecommendationProvider() {
  const reqStatus = await request('/provider/recommendation', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data
}

export async function getLotteryData() {
  const reqStatus = await request('/lottery', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data
}

export async function getWinnerGame() {
  const reqStatus = await request('/game/winner', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data
}

export async function getProviderList() {
  const reqStatus = await request('/provider', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data.data
}

export async function getProviderGame(providerCode, type) {
  const reqStatus = await request(`/provider/${providerCode}/${type}`, 'GET')
  if (reqStatus.error) return false

  return reqStatus.data.data
}

export async function launchGame(providerCode, gameCode) {
  const reqStatus = await request(`/launch/${providerCode}/${gameCode}?ios=${isIOS}`, 'GET')

  return reqStatus
}
