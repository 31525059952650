import Carousel from 'components/Caraousel'
import ProgressiveJackpot from 'components/ProgressiveJackpot'

const AboutScreen = ({ setLoginModal, setRegisterModal }) => {
  return (
    <main>
      <Carousel />
      <ProgressiveJackpot />
      <div className='bg-base-100 pt-0'>
        <div className='bg-base-200 p-3 text-content'>
          <h1 className='mb-2'>Tentang Kami</h1>
          <p>
            Selamat datang di HG88! Kami siap memberikan pengalaman judi online yang mengasyikkan
            dan menguntungkan bagi Anda. Bergabunglah sekarang dan nikmati berbagai bonus menarik
            yang kami tawarkan:
          </p>
          <h2 className='mb-2 mt-2'>Promosi dan Bonus Menarik</h2>
          <ul>
            <li>
              Bonus Deposit Pertama: Dapatkan bonus deposit sebesar 100% untuk setiap kali deposit
              pertama Anda.
            </li>
            <li>
              Cashback Mingguan: Setiap minggunya, kami memberikan cashback kepada pemain setia kami
              untuk setiap kekalahan yang dialami.
            </li>
            <li>
              Promo Reload: Isi ulang akun Anda dan dapatkan bonus ekstra untuk memperpanjang waktu
              bermain Anda.
            </li>
            <li>
              Turnamen Seru: Ikuti turnamen rutin kami dan bersaing untuk memenangkan hadiah-hadiah
              menarik!
            </li>
            <li>
              Ajak Teman: Bagikan kegembiraan bermain di HG88 dengan teman-teman Anda dan dapatkan
              bonus referral yang besar!
            </li>
          </ul>
          <p className='mb-2 mt-3'>
            Di HG88, kami berkomitmen untuk memberikan kemudahan dalam segala hal, termasuk dalam
            hal akses dan transaksi. Berikut adalah beberapa layanan yang kami sediakan:
          </p>
          <h2 className='mb-2'>Kemudahan Akses dan Transaksi</h2>
          <ul>
            <li>
              Website User-Friendly: Navigasi yang mudah dan intuitif membuat pengalaman bermain
              Anda lebih menyenangkan.
            </li>
            <li>
              Metode Deposit dan Withdraw: Kami menyediakan berbagai pilihan metode pembayaran yang
              aman dan cepat, termasuk transfer bank, e-wallet, dan cryptocurrency.
            </li>
            <li>
              Customer Support 24/7: Tim customer support kami siap membantu Anda kapan pun Anda
              membutuhkannya. Hubungi kami melalui live chat, telepon, atau email.
            </li>
          </ul>
          <p className='mb-2 mt-3'>
            Keamanan dan kepercayaan adalah prioritas utama kami di HG88. Kami memberikan jaminan
            berikut kepada para pemain:
          </p>
          <h2 className='mb-2'>Keamanan dan Kepercayaan</h2>
          <ul>
            <li>
              Sistem Keamanan Terbaik: Kami menggunakan teknologi keamanan terbaru untuk melindungi
              data pribadi dan transaksi keuangan Anda.
            </li>
            <li>
              Provider Terpercaya: Kami hanya bekerja sama dengan provider judi yang terkemuka dan
              terpercaya di industri ini.
            </li>
            <li>
              Pembayaran Tepat Waktu: Kami menjamin pembayaran kemenangan Anda dengan cepat dan
              tepat waktu tanpa adanya penundaan.
            </li>
          </ul>
        </div>
      </div>
    </main>
  )
}

export default AboutScreen
