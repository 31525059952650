import Carousel from 'components/Caraousel'
import ProgressiveJackpot from 'components/ProgressiveJackpot'

const AgreementScreen = ({ setLoginModal, setRegisterModal }) => {
  return (
    <main>
      <Carousel />
      <ProgressiveJackpot />
      <div className='bg-base-100 pt-0'>
        <div className='bg-base-200 p-3 text-content'>
          <h1 className='mb-2'>Cara Bermain</h1>
          <p>
            Bermain adalah suatu aktivitas yang bisa memberikan kesenangan dan tantangan. Namun,
            penting untuk diingat bahwa setiap permainan memiliki risiko tertentu. Sebelum terjun ke
            dalamnya, penting untuk memahami resiko yang terlibat.
          </p>
          <h2 className='mb-2 mt-2'>Pertimbangkan Risiko</h2>
          <ul>
            <li>Pelajari peraturan permainan dengan seksama.</li>
            <li>Pertimbangkan faktor keamanan dan kesehatan.</li>
            <li>Pahami implikasi jangka panjang dari aktivitas tersebut.</li>
          </ul>
          <h2 className='mb-2 mt-2'>Sumber Terpercaya</h2>
          <ul>
            <li>
              Untuk mempelajari cara bermain secara menyeluruh dan aman, disarankan untuk mencari
              sumber terpercaya seperti instruksi resmi dari penyedia permainan, atau bergabung
              dengan komunitas yang ahli dalam bidang tersebut.
            </li>
            <li>
              Belajar dari pengalaman orang lain juga bisa menjadi sumber pengetahuan yang berharga.
            </li>
          </ul>
        </div>
      </div>
    </main>
  )
}

export default AgreementScreen
