import { useRef } from 'react'
import { Card } from 'react-daisyui'

const WinnerRecommendation = () => {
  const swiperElRef = useRef(null)

  const recommendationData = [
    { label: 'Gates_Of_Olympus', prize: 'Rp 58.2345.322' },
    { label: 'Lucky_Neko', prize: 'Rp 23.434.322' },
    { label: 'Mahjong_Ways_Two', prize: 'Rp 28.234.677' },
    { label: 'Mahjong_Ways', prize: 'Rp 32.275.436' },
    { label: 'Neko_Riches', prize: 'Rp 15.346.000' },
    { label: 'Starlight_Princess_1000', prize: 'Rp 14.684.335' },
    { label: 'Starlight_Princess', prize: 'Rp 12.695.456' },
    { label: 'Sweet_Bonanza', prize: 'Rp 19.845.356' },
    { label: 'Twilight_Princess', prize: 'Rp 11.934.533' },
    { label: 'Wild_Bandito', prize: 'Rp 8.349.346' },
  ]

  return (
    <div className='w-full'>
      <swiper-container
        ref={swiperElRef}
        autoplay-delay='2000'
        autoplay-disable-on-interaction='true'
        slides-per-view='3'
        space-between='15'
        navigation='false'
      >
        {recommendationData.map((e, index) => {
          return (
            <swiper-slide key={index}>
              <Card>
                <Card.Image
                  className='w-full'
                  src={`/images/hot_games/${e.label}.png`}
                  alt='Shoes'
                />
                <span className='text-xs text-center bg-seconday text-base-content'>{e.prize}</span>
                {/* <Card.Body>
                  <Card.Title tag='span'>Zeus!</Card.Title>
                  <p className='text-xs'>RTP Live </p>
                </Card.Body> */}
              </Card>
            </swiper-slide>
          )
        })}
      </swiper-container>
    </div>
  )
}

export default WinnerRecommendation
