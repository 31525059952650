import { register } from 'controllers/Auth'
import { useRef, useState } from 'react'
import { Button, Divider, Modal } from 'react-daisyui'
import { connect } from 'react-redux'

function RegisterModal({ setUser, registerModal, setRegisterModal, setLoginModal }) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordd, setPasswordd] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loading, setLoading] = useState(false)

  const inputUsername = useRef(null)
  const inputPassword = useRef(null)
  const inputPasswordd = useRef(null)
  const inputPhone = useRef(null)

  const handleUsernameChange = (e) => {
    setUsername(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handlePassworddChange = (e) => {
    setPasswordd(e.target.value)
  }

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value)
  }

  const handleUsernameKey = (e) => {
    if (e.key === 'Enter') {
      inputPassword.current.focus()
    }
  }

  const handlePasswordKey = (e) => {
    if (e.key === 'Enter') {
      inputPasswordd.current.focus()
    }
  }

  const handlePassworddKey = (e) => {
    if (e.key === 'Enter') {
      inputPhone.current.focus()
    }
  }

  const handlePhoneKey = (e) => {
    if (e.key === 'Enter') {
      inputPhone.current.blur()
      handleRegistration()
    }
  }

  const handleRegistration = async () => {
    setLoading(true)
    const reqData = {
      username,
      password,
      phone_number: phoneNumber,
    }
    const registerData = await register(reqData)

    if (registerData) {
      setUsername('')
      setPassword('')
      setPasswordd('')
      setPhoneNumber('')
      setUser(registerData)
      setRegisterModal(false)
    }

    setLoading(false)
  }

  return (
    <Modal.Legacy
      open={registerModal}
      onClickBackdrop={loading ? null : setRegisterModal}
      className='bg-base-200'
    >
      <Button
        size='sm'
        color='ghost'
        shape='circle'
        className='absolute right-2 top-2'
        disabled={loading}
        onClick={setRegisterModal}
      >
        x
      </Button>
      <Modal.Header className='text-center'>REGISTRASI</Modal.Header>
      <Modal.Body>
        <div className='form-control'>
          <label className='label'>Nama Pengguna</label>
          <input
            type='text'
            className='input input-bordered'
            placeholder='BangGacor'
            ref={inputUsername}
            value={username}
            onChange={handleUsernameChange}
            onKeyUp={handleUsernameKey}
          />
        </div>
        <div className='form-control'>
          <label className='label'>Kata Sandi</label>
          <input
            type='password'
            className='input input-bordered'
            placeholder='******'
            ref={inputPassword}
            value={password}
            onChange={handlePasswordChange}
            onKeyUp={handlePasswordKey}
          />
        </div>
        <div className='form-control'>
          <label className='label'>Ulangi Kata Sandi</label>
          <input
            type='password'
            className='input input-bordered'
            placeholder='******'
            ref={inputPasswordd}
            value={passwordd}
            onChange={handlePassworddChange}
            onKeyUp={handlePassworddKey}
          />
        </div>
        <div className='form-control'>
          <label className='label'>Nomor Handphone</label>
          <input
            type='number'
            className='input input-bordered'
            placeholder='082382223456'
            ref={inputPhone}
            value={phoneNumber}
            onChange={handlePhoneChange}
            onKeyUp={handlePhoneKey}
          />
        </div>
      </Modal.Body>
      <Modal.Actions className='flex-col'>
        <Button
          variant='outline'
          color=''
          fullWidth={true}
          onClick={handleRegistration}
          loading={loading}
          disabled={loading}
        >
          REGISTRASI
        </Button>
        <Divider className='!ml-0'>Sudah Punya Akun?</Divider>
        <Button
          className='!ml-0'
          variant=''
          color='accent'
          fullWidth={true}
          onClick={setLoginModal}
          disabled={loading}
        >
          MASUK
        </Button>
      </Modal.Actions>
    </Modal.Legacy>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setUser: (e) => dispatch({ type: 'SET_USER', data: e }),
})

export default connect(null, mapDispatchToProps)(RegisterModal)
