import { getProviderGame } from 'controllers/Product'
import { useEffect, useRef, useState } from 'react'
import { Card, Loading } from 'react-daisyui'
import TogelScreen from 'screens/Togels'

const ListSlot = ({ type, availableProvider, provider, launchGame }) => {
  const [gameList, setGameList] = useState([])
  const [selectedProvider, setSelectedProvider] = useState(
    provider ? availableProvider.find((e) => e.provider_code === provider) : availableProvider[0]
  )
  const [loading, setLoading] = useState(false)

  const gameListRef = useRef(null)

  useEffect(() => {
    if (selectedProvider) {
      if (gameListRef.current) {
        gameListRef.current.scrollTop = 0
      }
      getGameList(selectedProvider.provider_code, type)
    }
  }, [selectedProvider, type])

  const getGameList = async (providerCode, type) => {
    setLoading(true)
    const gameData = await getProviderGame(providerCode, type)

    if (gameData) {
      setGameList(gameData)
    }
    setLoading(false)
  }
  console.log(type)
  const togel = type === 'TOGEL'
  if (togel) {
    return <TogelScreen />
  }

  if (['LC', 'SB', 'LK', 'CB'].includes(type)) {
    return (
      <div className='bg-base-200 flex-1 flex flex-row overflow-hidden'>
        <div className='w-full overflow-auto p-2 pl-1'>
          {availableProvider.map((e, index) => {
            return (
              <Card
                key={index}
                className='w-full p-1 inline-block text-nowrap'
                onClick={() => launchGame({ ...e, game_code: type })}
              >
                <Card.Image
                  className='w-full'
                  src={e.img_url.replace('.png', `_${type}.png`)}
                  alt={e.en_name}
                  loading='lazy'
                />
                <span className='py-2 text-xs text-center bg-base-100 border border-gold text-primary-content text-flow'>
                  {e.en_name}
                </span>
              </Card>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className='bg-base-200 flex-1 flex flex-row overflow-hidden'>
      <div className='overflow-auto max-w-12 pt-3 p-2 pl-1 pr-0'>
        <ul>
          {availableProvider.map((e, index) => {
            return (
              <li
                key={index}
                className={`py-1 p-1 ${selectedProvider === e ? 'border-gold' : 'border-base-300'} mb-2 border rounded-md`}
                onClick={() => setSelectedProvider(e)}
              >
                <img src={e.icon_url} alt={e.en_name} />
                {/* {e.en_name} */}
              </li>
            )
          })}
        </ul>
      </div>
      {loading ? (
        <div className='w-full h-full flex flex-col justify-center items-center'>
          <Loading size='lg' variant='infinity' />
          <span>Memuat Permainan...</span>
        </div>
      ) : (
        <div className='w-full overflow-auto p-2 pl-1' ref={gameListRef}>
          {gameList.map((e, index) => {
            return (
              <Card
                key={index}
                className='w-1/3 p-1 inline-block text-nowrap'
                onClick={() => launchGame(e)}
              >
                <Card.Image className='w-full' src={e.img_url} alt={e.en_name} loading='lazy' />
                <span className='text-xs text-center bg-seconday text-base-content text-flow'>
                  {e.en_name}
                </span>
              </Card>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ListSlot
