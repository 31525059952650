import { useEffect, useState } from 'react'
import './ProgressiveJackpot.css'

const ProgressiveJackpot = () => {
  const [jackpotAmount, setJackpotAmount] = useState(1234476)

  useEffect(() => {
    const interval = setInterval(() => {
      updateNumber()
    }, 100)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const updateNumber = () => {
    const increment = Math.floor(Math.random() * 5000) + 1
    setJackpotAmount((prevAmount) => prevAmount + increment)
  }

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }

  return (
    <div className='pt-1 bg-base-200'>
      <div className='jackpot-container'>
        <span>IDR</span> {formatNumber(jackpotAmount)}
      </div>
    </div>
  )
}

export default ProgressiveJackpot
