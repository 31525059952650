import BankStatus from 'components/BankStatus'
import Carousel from 'components/Caraousel'
import LoadingView from 'components/LoadingView'
import PlayerActivity from 'components/PlayerActivity'
import PopulerRecommendation from 'components/PopulerRecommendation'
import ProgressiveJackpot from 'components/ProgressiveJackpot'
import ProviderRecommendation from 'components/ProviderRecommendation'
import WinnerRecommendation from 'components/WinnerRecommendation'

import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useParams } from 'react-router-dom'

import ListSlot from 'components/ListSlot'

import CategoriesList from 'components/CategoriesList'
import FooterBar from 'components/FooterBar'
import LoginRegister from 'components/LoginRegister'

import { getProviderList, getRecommendationGame, launchGame } from 'controllers/Product'
import { sessionData } from 'controllers/Referral'

const HomeScreen = ({ user, banner, setLaunchWeb, setLoginModal, setRegisterModal }) => {
  const { referralCode } = useParams()

  useEffect(() => {
    if (referralCode && !user) {
      sessionData({ ref: referralCode })
    }
  }, [referralCode, user])

  const location = useLocation()
  const { pathname } = location
  const prevPathnameRef = useRef('')
  const [category, setCategory] = useState({
    label: 'POPULER',
    type: 'HOT',
  })
  const [recommendationData, setRecommendationData] = useState([])
  const [providerData, setProviderData] = useState([])
  const [loading, setLoading] = useState(false)

  const getRecommendationData = async () => {
    const recommendationData = await getRecommendationGame()
    if (recommendationData) {
      setRecommendationData(recommendationData)
    }
  }

  const getProvider = async () => {
    const providerData = await getProviderList()

    if (providerData) {
      setProviderData(providerData)
    }
  }

  useEffect(() => {
    getRecommendationData()
    getProvider()
  }, [])

  // useEffect(() => {
  //   if (prevPathnameRef.current && location.pathname !== prevPathnameRef.current) {
  //     const categori = location.pathname.split('/')
  //     setCategory(categori[2] ? categori[2].toLowerCase() : 'populer')
  //   }

  //   prevPathnameRef.current = location.pathname
  // }, [location])

  const requestLaunchGame = async (game) => {
    if (!user) return setLoginModal(true)
    setLoading(true)
    const launchStatus = await launchGame(game.provider_code, game.game_code)

    if (!launchStatus.error) {
      setLaunchWeb(launchStatus.data.data)
    } else {
      toast.error(launchStatus.msg)
    }

    setLoading(false)
  }

  const isHome = category?.type === 'HOT' || pathname === '/'

  const renderCategoryData = ({ type, selectedProvider }) => {
    switch (type) {
      default:
      case '':
        return (
          // <div className='bg-base-200 p-3'>
          // {/* <h2 className='mb-2 text-primary-content'>{label.toUpperCase().replace('_', ' ')}</h2> */}
          <ListSlot
            type={type}
            provider={selectedProvider}
            availableProvider={providerData.filter((e) => e.game_type.includes(type))}
            launchGame={requestLaunchGame}
          />
          // </div>
        )
    }
  }

  return (
    <main className={`${!isHome ? 'overflow-auto flex-1 flex flex-col' : ''}`}>
      {loading && <LoadingView text='Persiapan Gacor...' />}
      {isHome && (
        <>
          <Carousel banner={banner} />
          {!user && (
            <>
              <LoginRegister setLoginModal={setLoginModal} setRegisterModal={setRegisterModal} />
              <ProgressiveJackpot />
            </>
          )}
        </>
      )}
      <CategoriesList providerData={providerData} category={category} setCategory={setCategory} />
      {isHome ? (
        <>
          {!user && <PlayerActivity />}
          <div className='bg-base-100 pt-0'>
            <div className='bg-base-200 p-3'>
              <h2 className='mb-2'>Game Terpopuler</h2>
              <PopulerRecommendation data={recommendationData} launchGame={requestLaunchGame} />
            </div>
            {/* <div className='bg-base-200 p-3 pt-0'>
              <div className='flex flex-row justify-between'>
                <h2 className='mb-2'>Pasaran Togel</h2>
                <Input size='xs' placeholder='Cari Pasaran...' />
              </div>
              <LotterySummary />
            </div> */}
            <div className='bg-base-200 p-3 pt-0'>
              <h2 className='mb-2'>Rekomendasi Provider</h2>
              <ProviderRecommendation data={providerData} setCategory={setCategory} />
            </div>
            <div className='bg-base-200 p-3 pt-0'>
              <h2 className='mb-2'>Menang Terbesar Hari ini</h2>
              <WinnerRecommendation />
            </div>
            <div className='bg-base-300 p-3'>
              <BankStatus />
            </div>
            {!user && <FooterBar />}
            <div className='bg-base-100 text-center p-3 text-xs pb-6'>
              Copyright © 2024 HgEngine. All rights reserved.
            </div>
          </div>
        </>
      ) : (
        <div className='bg-base-100 pt-0 flex flex-1 overflow-auto'>
          {renderCategoryData(category)}
        </div>
      )}
    </main>
  )
}

export default HomeScreen
