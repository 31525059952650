import { request } from 'helpers/Common'
import toast from 'react-hot-toast'

export async function getBankOption() {
  const reqStatus = await request('/payment/options', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data
}

export async function getClientBank() {
  const reqStatus = await request('/payment/banks', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data.data
}

export async function saveClientBank(e) {
  const reqStatus = await request('/payment/bank', 'POST', e)
  if (reqStatus.error) {
    toast.error(reqStatus.msg)
    return false
  }
  toast.success(reqStatus.data.msg)

  return reqStatus.data.data
}

export async function getStatus() {
  const reqStatus = await request('/payment/status', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data.data
}

export async function getDepositOption() {
  const reqStatus = await request('/payment/deposit', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data.data
}

export async function submitDeposit(e) {
  const reqStatus = await request('/payment/deposit', 'POST', e, true)
  if (reqStatus.error) return false

  return reqStatus.data.data
}

export async function getWithdrawStatus() {
  const reqStatus = await request('/payment/withdraw/status', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data
}

export async function getWithdrawOption() {
  const reqStatus = await request('/payment/withdraw', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data
}

export async function submitWithdraw(e) {
  const reqStatus = await request('/payment/withdraw', 'POST', e, true)
  if (reqStatus.error) return false

  return reqStatus.data
}
