import { Loading } from 'react-daisyui'

const LoadingView = ({ text = 'Memuat...' }) => {
  return (
    <div className='fixed z-10 w-full h-full bg-[#000000ad] flex flex-col justify-center items-center pb-16'>
      <Loading size='lg' variant='infinity' />
      <span>{text}</span>
    </div>
  )
}

export default LoadingView
