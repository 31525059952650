import { request } from 'helpers/Common'

export async function sessionData(e) {
  const reqStatus = await request('/public/web/dataSession', 'POST', e)
  if (reqStatus.error) return false

  return reqStatus.data
}

export async function getRefData() {
  const reqStatus = await request('/referral', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data
}

export async function getHistoryReward() {
  const reqStatus = await request('/referral/history', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data
}
