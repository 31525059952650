const ReferralSystemScreen = ({ user }) => {
  return (
    <main className='bg-gradient-to-br from-blue-500 to-purple-600'>
      <div className='max-w-4xl mx-auto'>
        {/* <UserBalance /> */}
        <div className='bg-white shadow-lg rounded-lg p-4 mt-8'>
          <h1 className='text-xl font-bold text-left mb-8 text-gray-800'>
            Hanya di HG Engine,
            <br />
            Sistem referral bagi hasil
          </h1>
          {user ? (
            <div>
              <h1 className='text-3xl font-bold text-center mb-4 text-gray-800'>KODE REFERRAL</h1>
              <h1 className='text-2xl font-bold text-center mb-8 text-gray-800'>{user.ref}</h1>
            </div>
          ) : (
            <div>
              <h1 className='text-lg font-bold text-center mb-2 text-gray-800'>
                Masuk akun untuk mendapatkan kode
              </h1>
              <h1 className='text-base font-bold text-center mb-8 text-gray-800'>
                Bonus dari deposit dan kekalahan downline
              </h1>
            </div>
          )}
          {/* <div className='flex justify-center items-center mb-8'>
            <span className='text-lg font-semibold text-gray-700 mr-2'>Kode Referral Agen:</span>
            <Input color='primary' value='ref=5234234099' readOnly className='w-48' />
            <Button size='xs' color='success'>
              Salin
            </Button>
          </div>
          <Divider className='my-8' />
          <div className='grid grid-cols-2 gap-8'>
            <div className='text-center'>
              <p className='text-gray-700 mb-2 text-lg'>Total Downline</p>
              <p className='text-4xl font-semibold text-purple-600'>5</p>
            </div>
            <div className='text-center'>
              <p className='text-gray-700 mb-2 text-lg'>Total Downline Aktif</p>
              <p className='text-4xl font-semibold text-purple-600'>3</p>
            </div>
          </div>
          <Divider className='my-8' />
          <div className='grid grid-cols-2 gap-8'>
            <div>
              <p className='text-gray-700 mb-2 text-lg'>Komisi Hari ini</p>
              <p className='text-2xl font-semibold text-green-500'>+Rp 100,000</p>
            </div>
            <div>
              <p className='text-gray-700 mb-2 text-lg'>Jumlah Downline aktif</p>
              <div className='flex items-center'>
                <p className='text-lg font-semibold'>8</p>
              </div>
            </div>
          </div>
          <Divider className='my-8' />
          <div>
            <h2 className='text-xl font-bold mb-4 text-gray-800'>Riwayat Pendapatan Anda</h2>
            <Table size='md'>
              <Table.Head>
                <span>Date</span>
                <span>Transaction</span>
                <span>Amount</span>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <span>2024-03-25</span>
                  <span>Komisi</span>
                  <span>+Rp 50,000</span>
                </Table.Row>
                <Table.Row>
                  <span>2024-03-24</span>
                  <span>Komisi</span>
                  <span>+Rp 30,000</span>
                </Table.Row>
              </Table.Body>
            </Table>
          </div> */}
        </div>
      </div>
    </main>
  )
}

export default ReferralSystemScreen
