import { Badge, BottomNavigation, Indicator } from 'react-daisyui'
import { Link, useLocation } from 'react-router-dom'

const NavBottom = () => {
  const location = useLocation()

  return (
    <BottomNavigation className='relative z-10 border-t border-t-base-content h-12'>
      <Link
        to='/'
        className={
          location.pathname === '/' ? 'text-primary-content border-t border-t-primary-content' : ''
        }
      >
        <BottomNavigation.Item>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className={`w-6 h-6`}
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6'
            />
          </svg>
          <label className='text-xs'>Beranda</label>
        </BottomNavigation.Item>
      </Link>
      <Link
        to='/dompet'
        className={
          location.pathname.includes('/dompet')
            ? 'text-primary-content border-t border-t-primary-content'
            : ''
        }
      >
        <BottomNavigation.Item>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className={`w-6 h-6`}
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3'
            />
          </svg>

          <label className='text-xs'>Dompet</label>
        </BottomNavigation.Item>
      </Link>
      <Link
        to='/promosi'
        className={location.pathname === '/promosi' ? 'text-primary-content' : ''}
      >
        <BottomNavigation.Item
          className={`highlight-menu ${location.pathname === '/promosi' ? 'highlight-menu-active' : ''}`}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z'
            />
          </svg>
          <label className='text-xs'>Promosi</label>
        </BottomNavigation.Item>
      </Link>
      <Link
        to='/layanan-pelanggan'
        className={
          location.pathname === '/layanan-pelanggan'
            ? 'text-primary-content border-t border-t-primary-content'
            : ''
        }
      >
        <BottomNavigation.Item>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z'
            />
          </svg>

          <label className='text-xs'>Live Chat</label>
        </BottomNavigation.Item>
      </Link>
      <Link
        to='/referral'
        className={
          location.pathname === '/referral'
            ? 'text-primary-content border-t border-t-primary-content'
            : ''
        }
      >
        <BottomNavigation.Item>
          <Indicator className=''>
            <Badge
              size='xs'
              color='info'
              className={[Indicator.Item.className(), 'scale-animation']}
            >
              +23Jt
            </Badge>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z'
              />
            </svg>
          </Indicator>

          <label className='text-xs'>Referral</label>
        </BottomNavigation.Item>
      </Link>
    </BottomNavigation>
  )
}

export default NavBottom
