import WebView from 'components/WebView'
import { useState } from 'react'
import { ChatBubble } from 'react-daisyui'

const LiveChatScreen = ({ config }) => {
  const [liveChat, setLiveChat] = useState(false)
  if (!config) return null

  if (liveChat) {
    return (
      <main className='flex flex-col flex-1'>
        <WebView className='h-0' full={true} url={config.live_chat_url} />
      </main>
    )
  }

  return (
    <main className='flex flex-col flex-1'>
      <div className='bg-base-300 text-content'>
        <h1 className='mb-2 p-3 pb-0'>Layanan Bantuan</h1>
        <div className='p-3 bg-base-200'>
          Jika anda mengalami kesulitan maupun kendala, silahkan hubungi layanan bantuan kami.
        </div>
      </div>
      <div className='py-3'>
        {(config.cs_wa_1 || config.cs_wa_2 || config.cs_wa_3) && (
          <div className='px-3 pb-2 mb-2 bg-base-200'>
            <h1 className='mb-2 p-3 pb-0'>CS Whatsapp</h1>
            {config.cs_wa_1 && (
              <a
                href={`https://wa.me/${config.cs_wa_1}`}
                target='_blank'
                className='link link-hover'
                rel='noreferrer'
              >
                <ChatBubble>
                  <ChatBubble.Avatar src='https://asset.68temp.com/general/whatsapp.gif' />
                  <ChatBubble.Message>{config.cs_wa_1}</ChatBubble.Message>
                </ChatBubble>
              </a>
            )}
            {config.cs_wa_2 && (
              <a
                href={`https://wa.me/${config.cs_wa_2}`}
                target='_blank'
                className='link link-hover'
                rel='noreferrer'
              >
                <ChatBubble>
                  <ChatBubble.Avatar src='https://asset.68temp.com/general/whatsapp.gif' />
                  <ChatBubble.Message>{config.cs_wa_2}</ChatBubble.Message>
                </ChatBubble>
              </a>
            )}
            {config.cs_wa_3 && (
              <a
                href={`https://wa.me/${config.cs_wa_3}`}
                target='_blank'
                className='link link-hover'
                rel='noreferrer'
              >
                <ChatBubble>
                  <ChatBubble.Avatar src='https://asset.68temp.com/general/whatsapp.gif' />
                  <ChatBubble.Message>{config.cs_wa_3}</ChatBubble.Message>
                </ChatBubble>
              </a>
            )}
          </div>
        )}

        {(config.cs_telegram_1 || config.cs_telegram_2 || config.cs_telegram_3) && (
          <div className='px-3 pb-2 mb-2 bg-base-200'>
            <h1 className='mb-2 p-3 pb-0'>CS Telegram</h1>
            {config.cs_telegram_1 && (
              <a
                href={`https://t.me/${config.cs_telegram_1}`}
                target='_blank'
                className='link link-hover'
                rel='noreferrer'
              >
                <ChatBubble>
                  <ChatBubble.Avatar src='https://asset.68temp.com/general/telegram.gif' />
                  <ChatBubble.Message>{config.cs_telegram_1}</ChatBubble.Message>
                </ChatBubble>
              </a>
            )}
            {config.cs_telegram_2 && (
              <a
                href={`https://t.me/${config.cs_telegram_2}`}
                target='_blank'
                className='link link-hover'
                rel='noreferrer'
              >
                <ChatBubble>
                  <ChatBubble.Avatar src='https://asset.68temp.com/general/telegram.gif' />
                  <ChatBubble.Message>{config.cs_telegram_2}</ChatBubble.Message>
                </ChatBubble>
              </a>
            )}
            {config.cs_telegram_3 && (
              <a
                href={`https://t.me/${config.cs_telegram_3}`}
                target='_blank'
                className='link link-hover'
                rel='noreferrer'
              >
                <ChatBubble>
                  <ChatBubble.Avatar src='https://asset.68temp.com/general/telegram.gif' />
                  <ChatBubble.Message>{config.cs_telegram_3}</ChatBubble.Message>
                </ChatBubble>
              </a>
            )}
          </div>
        )}
        {(config.cs_instagram_1 || config.cs_instagram_2 || config.cs_instagram_3) && (
          <div className='px-3 pb-2 mb-2 bg-base-200'>
            <h1 className='mb-2 p-3 pb-0'>CS Instagram</h1>
            {config.cs_instagram_1 && (
              <a
                href={`https://www.instagram.com/${config.cs_instagram_1}`}
                target='_blank'
                className='link link-hover'
                rel='noreferrer'
              >
                <ChatBubble>
                  <ChatBubble.Avatar src='https://asset.68temp.com/general/instagram.gif' />
                  <ChatBubble.Message>{config.cs_instagram_1}</ChatBubble.Message>
                </ChatBubble>
              </a>
            )}
            {config.cs_instagram_2 && (
              <a
                href={`https://www.instagram.com/${config.cs_instagram_2}`}
                target='_blank'
                className='link link-hover'
                rel='noreferrer'
              >
                <ChatBubble>
                  <ChatBubble.Avatar src='https://asset.68temp.com/general/instagram.gif' />
                  <ChatBubble.Message>{config.cs_instagram_2}</ChatBubble.Message>
                </ChatBubble>
              </a>
            )}
            {config.cs_instagram_3 && (
              <a
                href={`https://www.instagram.com/${config.cs_instagram_3}`}
                target='_blank'
                className='link link-hover'
                rel='noreferrer'
              >
                <ChatBubble>
                  <ChatBubble.Avatar src='https://asset.68temp.com/general/instagram.gif' />
                  <ChatBubble.Message>{config.cs_instagram_3}</ChatBubble.Message>
                </ChatBubble>
              </a>
            )}
          </div>
        )}

        <div onClick={() => setLiveChat(true)} className='absolute w-28 h-16 right-1 bottom-14'>
          <img
            src='https://asset.68temp.com/general/live-chat.gif'
            alt='Live Chat'
            className='w-28 h-16'
          />
        </div>
      </div>
    </main>
  )
}

export default LiveChatScreen
