/* eslint-disable jsx-a11y/no-distracting-elements */
const RunningText = ({ text = [] }) => {
  return (
    <div className='h-6 border-b-gold border-t-2 border-t-gold border-b-0 bg-[#312402]'>
      <marquee scrollamount='5' className='text-xs text-white font-bold'>
        {text.map((e) => `${e}                   |                   `)}
      </marquee>
    </div>
  )
}

export default RunningText
