import { useState } from 'react'
import { Button, Collapse } from 'react-daisyui'

const TogelScreen = () => {
  const [selectedMenu, setSelectedMenu] = useState('MK')
  const [betData, setBetData] = useState(Array.from({ length: 10 }, () => ({})))

  const togelMenu = [
    {
      label: 'PASARAN',
      id: 'MK',
    },
    {
      label: 'HADIAH',
      id: 'RW',
    },
    {
      label: 'BERTARUH',
      id: 'BT',
    },
    {
      label: 'TARUHAN SAYA',
      id: 'MS',
    },
  ]

  const pools = [
    {
      label: 'SINGAPORE POOLS',
      id: 'SG',
      bet_time_label: 'Setiap Hari',
      state_label: 'BUKA',
      open: true,
    },
    {
      label: 'HONGKONG POOLS',
      id: 'HK',
      bet_time_label: 'Setiap Hari',
      state_label: 'BUKA',
      open: true,
    },
    {
      label: 'SYDNEY POOLS',
      id: 'SD',
      bet_time_label: 'Setiap Hari',
      state_label: 'BUKA',
      open: true,
    },
    {
      label: 'MACAU POOLS',
      id: 'MC',
      bet_time_label: 'Setiap Hari',
      state_label: 'TUTUP',
      open: false,
    },
  ]

  const reset4D = (e) => {}

  const submit4D = (e) => {}

  const getGameRules = (e) => {}

  const renderContent = () => {
    switch (selectedMenu) {
      case 'MK':
        return (
          <div className='flex flex-wrap pt-3 pr-3'>
            {pools.map((e, index) => (
              <div key={index} className='w-1/2 lg:w-1/3 xl:w-1/4 p-3 pr-0 pt-0'>
                <div className='border border-base-300 rounded-md  text-center text-primary-content'>
                  <div className='bg-base-200 p-2'>
                    <h1 className='text-sm'>{e.label}</h1>
                  </div>
                  <div className='bg-base-300 p-2 text-xs'>{e.bet_time_label}</div>
                  <div className='bg-base-200 text-xs py-1'>Waktu Tutup: 19:30</div>
                  <div className={`${e.open ? 'bg-green-700' : 'bg-red-700'} p-1 text-xs`}>
                    {e.state_label}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )
      case 'RW':
        return (
          <div className='flex flex-wrap pt-3 pr-3'>
            {pools.map((e, index) => (
              <Collapse
                key={index}
                icon='arrow'
                className='border rounded-none border-base-300 bg-base-200 m-3 mr-0 mt-0 text-primary-content'
              >
                <Collapse.Title className='text-md min-h-fit py-3'>{e.label}</Collapse.Title>
                <Collapse.Content className='bg-base-300'>
                  <div className='w-full overflow-hidden pt-3'>
                    <table class='w-full overflow-hidden text-xs text-center'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>4A</th>
                          <th>3A</th>
                          <th>2A</th>
                          <th>2A Dpn</th>
                          <th>2A Tgh</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Discount</td>
                          <td> 65 </td>
                          <td> 58 </td>
                          <td> 29 </td>
                          <td> 29 </td>
                          <td> 29 </td>
                        </tr>
                        <tr>
                          <td>Hadiah</td>
                          <td> 3000 </td>
                          <td> 400 </td>
                          <td> 70 </td>
                          <td> 65 </td>
                          <td> 65 </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Collapse.Content>
              </Collapse>
            ))}
          </div>
        )
      case 'BT':
        return (
          <div className='flex flex-1 flex-col text-primary-content'>
            <div className='flex flex-col p-3 bg-base-200 border-b-2 border-base-300'>
              <div>Settingan Bet</div>
              <div className='flex flex-row'>
                <div className='mr-3 flex flex-col'>
                  <span>Baris:</span>
                  <select className='border border-gray-300 rounded px-2 py-1'>
                    <option value='5' selected='selected'>
                      5
                    </option>
                    <option value='10'>10</option>
                    <option value='20'>20</option>
                    <option value='30'>30</option>
                    <option value='40'>40</option>
                    <option value='50'>50</option>
                  </select>
                </div>
                <div className='flex flex-1 flex-row gap-1'>
                  <div className='w-1/3 flex flex-col'>
                    <span>4A</span>
                    <input
                      type='number'
                      className='border border-gray-300 rounded px-2 py-1 w-full'
                      name='b4_autobet'
                      placeholder='Auto Bet 4A'
                    />
                  </div>
                  <div className='w-1/3 flex flex-col'>
                    <span>3A</span>
                    <input
                      type='number'
                      className='border border-gray-300 rounded px-2 py-1 w-full'
                      name='b3_autobet'
                      placeholder='Auto Bet 3A'
                    />
                  </div>
                  <div className='w-1/3 flex flex-col'>
                    <span>2A</span>
                    <input
                      type='number'
                      className='border border-gray-300 rounded px-2 py-1 w-full'
                      name='b2_autobet'
                      placeholder='Auto Bet 2A'
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='flex-1 flex flex-col overflow-auto'>
              {betData.map((e, index) => (
                <div
                  index={index}
                  className='flex flex-row flex-1 p-3 pl-0 border-b border-base-300'
                >
                  <div className='w-8 justify-center items-center flex px-3'>
                    <span>{index + 1}</span>
                  </div>
                  <div className='flex flex-col flex-1'>
                    <div className='flex flex-row mb-1 gap-1'>
                      <div className='w-1/3'>
                        <select
                          name='btype'
                          className='border border-gray-300 rounded px-2 py-1 w-full'
                        >
                          <option value='1' selected='selected'>
                            Normal
                          </option>
                          <option value='2'>BB</option>
                          <option value='3'>2D Depan</option>
                          <option value='4'>2D Tengah</option>
                        </select>
                      </div>
                      <div className='w-1/3'>
                        <input
                          type='text'
                          name='number'
                          placeholder='Number'
                          inputmode='numeric'
                          className='border border-gray-300 rounded px-2 py-1 w-full'
                        />
                      </div>
                      <div className='w-1/3'>
                        <select
                          name='discount'
                          className='border border-gray-300 rounded px-2 py-1 w-full'
                        >
                          <option value='1' selected='selected' dca='1'>
                            Diskon
                          </option>
                          <option value='0' dca='0'>
                            No Diskon
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className='flex flex-row gap-1'>
                      <div className='w-1/4'>
                        <input
                          className='border border-gray-300 rounded px-2 py-1 w-full'
                          type='text'
                          name='b4a'
                          placeholder='4A (60%)'
                          inputmode='numeric'
                          disabled=''
                        />
                      </div>
                      <div className='w-1/4'>
                        <input
                          className='border border-gray-300 rounded px-2 py-1 w-full'
                          type='text'
                          name='b3a'
                          placeholder='3A (58%)'
                          inputmode='numeric'
                          disabled=''
                        />
                      </div>
                      <div className='w-1/4'>
                        <input
                          className='border border-gray-300 rounded px-2 py-1 w-full'
                          type='text'
                          name='b2a'
                          placeholder='2A (29%)'
                          inputmode='numeric'
                          disabled=''
                        />
                      </div>
                      <div className='w-1/4'>
                        <input
                          type='text'
                          name='total'
                          disabled=''
                          placeholder='Total'
                          className='border border-gray-300 rounded px-2 py-1 w-full'
                        />
                      </div>
                    </div>
                  </div>
                  <div className=''>
                    <div>
                      <button type='button' className=''></button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className='bg-base-200 p-3'>
              <div className='flex flex-row gap-1'>
                <div className='w-1/2 '>
                  <Button
                    size='sm'
                    className={`w-full border border-gold bg-base-200 text-primary-content highlight-button rounded-sm`}
                    onClick={() => {}}
                  >
                    Reset
                  </Button>
                </div>
                <div className='w-1/2 '>
                  <Button
                    size='sm'
                    className={`w-full border border-gold bg-base-200 text-primary-content highlight-button rounded-sm`}
                    onClick={() => {}}
                  >
                    Kirim
                  </Button>
                </div>
              </div>
              <div className='py-2'>
                Batas Taruhan: 4A -{' '}
                <span className='red'>
                  <span className='b4d-4a-min'>100</span> ~{' '}
                  <span className='b4d-4a-max'>10,000</span>
                </span>{' '}
                Per Taruhan; 3A -{' '}
                <span className='red'>
                  <span className='b4d-3a-min'>100</span> ~{' '}
                  <span className='b4d-3a-max'>10,000</span>
                </span>{' '}
                Per Taruhan; 2A -{' '}
                <span className='red'>
                  <span className='b4d-2a-min'>100</span> ~{' '}
                  <span className='b4d-2a-max'>10,000</span>
                </span>{' '}
                Per Taruhan
              </div>
            </div>
          </div>
        )
      default:
    }
  }

  return (
    <div className='bg-base-100 flex-1 flex flex-col overflow-hidden'>
      <div className='w-full overflow-x-auto gap-2 inline-flex py-2 px-3'>
        {togelMenu.map((e, index) => (
          <Button
            key={index}
            size='sm'
            className={`w-1/3 border ${selectedMenu === e.id ? 'border-gold' : 'border-base-300'}  bg-base-200 text-primary-content highlight-button rounded-sm`}
            onClick={() => setSelectedMenu(e.id)}
          >
            {e.label}
          </Button>
        ))}
      </div>
      <div
        className={`flex ${!['BT'].includes(selectedMenu) ? 'flex-wrap' : 'flex-1'} overflow-y-auto`}
      >
        {renderContent()}
      </div>
    </div>
  )
}

export default TogelScreen
