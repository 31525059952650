/* eslint-disable react-hooks/rules-of-hooks */
import { formatBalance } from 'helpers/Common'
import { useState } from 'react'
import { Button } from 'react-daisyui'

const RebateData = ({ data }) => {
  if (!data) return null

  const { on_going, ready, claimed } = data
  const [loading, setLoading] = useState(false)

  const requestUpdate = async () => {
    setLoading(true)
    setLoading(false)
  }

  return (
    <div className='p-3 bg-base-200 text-primary-content'>
      <div className='flex flex-row justify-between'>
        <h1 className='text-md'>Estimasi Rebate Besok</h1>
        <h2 className='text-md text-warning'>{formatBalance(on_going)}</h2>
      </div>
      <div className='flex flex-row justify-between'>
        <h1 className='text-md'>LifeTime Rebate</h1>
        <h2 className='text-md text-warning'>{formatBalance(claimed)}</h2>
      </div>
      <div className='flex flex-row justify-between'>
        <h1 className='text-md'>Rebate Hari ini</h1>
        <h2 className='text-md text-warning'>{formatBalance(ready)}</h2>
      </div>
      {ready > 0 && (
        <div className='flex flex-row justify-end'>
          <Button
            size='sm'
            className='border border-gold bg-goldline text-gold-content highlight-button rounded-sm w-1/2'
          >
            Claim
          </Button>
        </div>
      )}
    </div>
  )
}

export default RebateData
