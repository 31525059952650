import { useEffect, useState } from 'react'
import './PlayerActivity.css'

const winActivities = [
  { player: 'John', prize: 'Rp 50.000', game: 'slot pragmatic' },
  { player: 'Alice', prize: 'Rp 100.000', game: 'blackjack' },
  { player: 'Bob', prize: 'Rp 75.000', game: 'roulette' },
  { player: 'Eva', prize: 'Rp 200.000', game: 'bingo' },
  { player: 'Mike', prize: 'Rp 150.000', game: 'poker' },
  { player: 'Andi', prize: 'Rp 140.000', game: 'mahjong' },
]

const PlayerActivity = () => {
  const [scrollIndex, setScrollIndex] = useState(0)
  const [showData, setShowData] = useState([])

  useEffect(() => {
    const interval = setInterval(() => {
      const endIndex = (scrollIndex + 2) % winActivities.length
      const newData = []
      if (endIndex > scrollIndex) {
        newData.push(winActivities[scrollIndex], winActivities[endIndex])
      } else {
        newData.push(winActivities[scrollIndex], winActivities[0])
      }
      setShowData(newData)
      setScrollIndex(endIndex)
    }, 3000)
    return () => clearInterval(interval)
  }, [scrollIndex])

  return (
    <div>
      <div className='player-activity-container justify-center bg-base-200 text-center h-12'>
        {showData.map((activity, index) => (
          <div key={index + new Date().getTime()} className={`activity-item-${index}`}>
            <span className='text-xs'>
              {activity.player} memenangkan <br />
              {activity.prize} di {activity.game}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PlayerActivity
