import { Card } from 'react-daisyui'
import { useNavigate } from 'react-router-dom'

const PopulerRecommendation = ({ data = [], setCategory }) => {
  let navigate = useNavigate()

  const handleClick = (e) => {
    console.log(e)
    setCategory({
      label: 'SLOT',
      type: 'SL',
      selectedProvider: e.provider_code,
    })
    navigate('categori/slot')
  }

  return (
    <div className='w-full'>
      {data.slice(0, 8).map((e, index) => {
        return (
          <Card className='w-1/2 inline-block' key={index} onClick={() => handleClick(e)}>
            <Card.Image className='w-full inline-block' src={e.img_url} alt={e.en_name} />
          </Card>
        )
      })}
    </div>
  )
}

export default PopulerRecommendation
