import { useRef } from 'react'
import { Card } from 'react-daisyui'

const PopulerRecommendation = ({ data = [], launchGame }) => {
  const swiperElRef = useRef(null)

  return (
    <div className='w-full'>
      <swiper-container
        ref={swiperElRef}
        autoplay-delay='2000'
        autoplay-disable-on-interaction='true'
        slides-per-view='3'
        space-between='15'
        navigation='false'
      >
        {data.map((e, index) => {
          return (
            <swiper-slide key={index}>
              <Card onClick={() => launchGame(e)}>
                <Card.Image className='w-full' src={e.img_url} alt={e.en_name} />
                <span className='text-xs text-center bg-seconday text-base-content'>
                  {e.en_name}
                </span>
              </Card>
            </swiper-slide>
          )
        })}
      </swiper-container>
    </div>
  )
}

export default PopulerRecommendation
