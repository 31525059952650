import { Footer } from 'react-daisyui'

const FooterBar = () => {
  return (
    <div>
      <Footer className='p-10 bg-base-200 text-neutral-content'>
        <div>
          <Footer.Title>Informasi Layanan</Footer.Title>
          <a href='/tentang-kami' className='link link-hover'>
            Tentang Kami
          </a>
          <a href='/layanan-pelanggan' className='link link-hover'>
            Kontak Kami
          </a>
          <a href='/cara-bermain' className='link link-hover'>
            Cara Bermain
          </a>
          <a href='/perjanjian-pengguna' className='link link-hover'>
            Perjanjian Pengguna
          </a>
          <a href='/referral' className='link link-hover'>
            Sistem Referral
          </a>
        </div>
      </Footer>
    </div>
  )
}

export default FooterBar
