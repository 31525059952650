import { useEffect, useRef } from 'react'

const Caraousel = ({ banner = [] }) => {
  const swiperElRef = useRef(null)

  useEffect(() => {
    swiperElRef.current.addEventListener('swiperprogress', (e) => {
      const [swiper, progress] = e.detail
      // console.log(progress)
    })

    swiperElRef.current.addEventListener('swiperslidechange', (e) => {
      // console.log('slide changed')
    })
  }, [])

  return (
    <div className='w-full'>
      <swiper-container
        ref={swiperElRef}
        autoplay-delay='2000'
        autoplay-disable-on-interaction='true'
        slides-per-view='1'
        navigation='false'
        pagination='false'
      >
        {banner.map((e, index) => (
          <swiper-slide key={index}>
            <img src={e.img_url} alt={e.title} />
          </swiper-slide>
        ))}
      </swiper-container>
    </div>
  )
}

export default Caraousel
