import { combineReducers } from 'redux'

const initialState = {
  user: null,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.data }
    case 'CLEAR_USER':
      return { ...state, user: null }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  user: userReducer,
})

export default rootReducer
