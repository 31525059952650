import { useEffect, useState } from 'react'
import { Button, Divider, Modal } from 'react-daisyui'
import { isIOS } from 'react-device-detect'
import LoadingView from './LoadingView'

function GameWeb({ data, setLaunchWeb, full }) {
  const [isLoading, setIsLoading] = useState(true)
  const [launchPopUp, setLaunchPopUp] = useState(false)

  const handleLoad = () => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  useEffect(() => {
    if (!data.in_app) {
      if (isIOS) return setLaunchPopUp(true)

      window.open(data.game_url, '_blank')?.focus()
    }
  }, [data])

  const handleClose = () => {
    setLaunchPopUp(false)
  }

  const handleLaunch = async () => {
    setLaunchPopUp(false)
  }

  if (!data.in_app && isIOS)
    return (
      <Modal.Legacy open={launchPopUp} onClickBackdrop={handleClose} className='bg-base-200'>
        <Modal.Header className='text-center'>
          <Divider className='!ml-0'>Luncurkan Permainan</Divider>
        </Modal.Header>

        <Modal.Actions className='flex-col'>
          <Button
            tag='a'
            target='_blank'
            href={data.game_url}
            className={`w-full mt-3 bg-goldline text-gold-content border-gold border-2`}
            color=''
            fullWidth={true}
            onClick={handleLaunch}
          >
            MULAI BERMAIN
          </Button>
        </Modal.Actions>
      </Modal.Legacy>
    )

  if (!data.in_app && !isIOS) return null

  return (
    <div className='fixed w-full h-full z-10 top-0 flex flex-col'>
      <div className='flex justify-end absolute'>
        {!full && (
          <div
            className='cursor-pointer px-1 border border-gold ml-1 mt-1 rounded-md'
            onClick={() => setLaunchWeb(null)}
          >
            <h1>LOBBY</h1>
          </div>
        )}
      </div>
      {isLoading && <LoadingView />}
      <iframe title='Web View' src={data.game_url} className='flex-1' onLoad={handleLoad} />
    </div>
  )
}

export default GameWeb
