import { formatBalance } from 'helpers/Common'

const status = {
  cancelled: 'Di Batalkan',
  rejected: 'Di Tolak',
  pending: 'Pending',
  expired: 'Kadaluarsa',
  completed: 'Berhasil',
}

const WithdrawHistory = ({ data }) => {
  if (data.length === 0) return null

  return (
    <div>
      <div className='flex mx-3 my-2 justify-between'>
        <h1 className='font-semibold'>Withdraw Terakhir</h1>
      </div>

      {data.slice(0, 1).map((e, index) => (
        <div
          key={index}
          className='bg-base-200 flex flex-col mx-3 mb-3 rounded-md p-3 border border-base-300 text-primary-content'
        >
          <div className='flex flex-row justify-between'>
            <span>{e.date}</span>
            <span>{status[e.status]}</span>
          </div>
          <hr className='mb-3 mt-1' />
          <div className='flex flex-row justify-between'>
            <span className=' text-lg'>Informasi Pembayaran</span>
          </div>
          <div className='flex flex-row justify-between'>
            <span>Metode</span>
            <span>{e.dest.title}</span>
          </div>
          <div className='flex flex-row justify-between'>
            <span>Biaya Admin</span>
            <span>{formatBalance(e.user_fee)}</span>
          </div>
          <hr />
          <div className='flex flex-row justify-between'>
            <span>TOTAL</span>
            <span>{formatBalance(e.total_amount)}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default WithdrawHistory
