import LoginModal from 'components/LoginModal'
import PhoneFrame from 'components/PhoneFrame'
import { connect } from 'react-redux'

import { BrowserRouter as Router, useRoutes } from 'react-router-dom'

import NavBottom from 'components/NavBottom'
import NavTop from 'components/NavTop'
import { Toaster } from 'react-hot-toast'

import GameWeb from 'components/GameWeb'
import RunningText from 'components/RunningText'
import './App.css'

import RegisterModal from 'components/RegisterModal'
import { useEffect, useState } from 'react'
import { register } from 'swiper/element/bundle'
import 'swiper/element/css/autoplay'

import AboutScreen from 'screens/AboutScreen'
import AgreementScreen from 'screens/AgreementScreen'
import GuideScreen from 'screens/GuideScreen'
import HomeScreen from 'screens/HomeScreen'
import LiveChatScreen from 'screens/LiveChatScreen'
import PromotionScreen from 'screens/Promotions'
import ReferralScreen from 'screens/Referrals'
import WalletScreen from 'screens/Wallets'

import { getConfig } from 'controllers/User'

register()

function Routes({ config, user, setLaunchWeb, setLoginModal, setRegisterModal }) {
  const element = useRoutes([
    {
      path: '/v/:referralCode',
      element: (
        <HomeScreen
          user={user}
          banner={config?.banner}
          setLaunchWeb={setLaunchWeb}
          setLoginModal={setLoginModal}
          setRegisterModal={setRegisterModal}
        />
      ),
    },
    {
      path: '/*',
      element: (
        <HomeScreen
          user={user}
          banner={config?.banner}
          setLaunchWeb={setLaunchWeb}
          setLoginModal={setLoginModal}
          setRegisterModal={setRegisterModal}
        />
      ),
    },
    {
      path: '/referral',
      element: <ReferralScreen user={user} />,
    },
    {
      path: '/promosi',
      element: <PromotionScreen user={user} />,
      children: [
        { path: '/promosi', element: <PromotionScreen /> },
        { path: '/promosi', element: <PromotionScreen /> },
        { path: ':slug', element: <PromotionScreen /> },
      ],
    },
    {
      path: '/dompet',
      element: <WalletScreen user={user} setLoginModal={setLoginModal} />,
      children: [
        { path: '/dompet/isi-saldo', element: <WalletScreen /> },
        { path: '/dompet/penarikan', element: <WalletScreen /> },
        { path: ':slug', element: <WalletScreen /> },
      ],
    },
    {
      path: '/layanan-pelanggan',
      element: <LiveChatScreen config={config} />,
      children: [
        { path: '/layanan-pelanggan', element: <LiveChatScreen /> },
        { path: '/layanan-pelanggan', element: <LiveChatScreen /> },
        { path: ':slug', element: <LiveChatScreen /> },
      ],
    },
    { path: '/tentang-kami', element: <AboutScreen /> },
    { path: '/perjanjian-pengguna', element: <AgreementScreen /> },
    { path: '/cara-bermain', element: <GuideScreen /> },
  ])
  return element
}

function App({ user }) {
  const [loginModal, setLoginModal] = useState(false)
  const [registerModal, setRegisterModal] = useState(false)
  const [launchWeb, setLaunchWeb] = useState(null)
  const [config, setConfig] = useState(null)

  useEffect(() => {
    retrieveConfig()
  }, [])

  useEffect(() => {
    console.log('user', user)
  }, [user])

  const retrieveConfig = async () => {
    const configData = await getConfig()
    console.log(configData)

    if (configData) {
      setConfig(configData)
    }
  }

  return (
    <Router>
      <PhoneFrame>
        <div className='flex flex-col w-full h-full' style={{ paddingTop: 0 + 'px' }}>
          <NavTop user={user} />
          <div id='content-container' className='bg-base-100 flex-1 flex flex-col overflow-y-auto'>
            <RunningText text={config?.home_message} />
            <Routes
              user={user}
              setLaunchWeb={setLaunchWeb}
              setLoginModal={setLoginModal}
              setRegisterModal={setRegisterModal}
              config={config}
            />
          </div>
          <NavBottom />
        </div>
        <LoginModal
          loginModal={loginModal}
          setLoginModal={() => {
            setRegisterModal(false)
            setLoginModal((prev) => !prev)
          }}
          setRegisterModal={() => {
            setLoginModal(false)
            setRegisterModal((prev) => !prev)
          }}
        />
        <RegisterModal
          registerModal={registerModal}
          setLoginModal={() => {
            setRegisterModal(false)
            setLoginModal((prev) => !prev)
          }}
          setRegisterModal={() => {
            setLoginModal(false)
            setRegisterModal((prev) => !prev)
          }}
        />
      </PhoneFrame>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: 'white',
            },
          },
          error: {
            style: {
              background: 'white',
            },
          },
        }}
      />
      {launchWeb && <GameWeb data={launchWeb} setLaunchWeb={setLaunchWeb} />}
    </Router>
  )
}

const mapStateToProps = (state) => ({
  user: state.user.user,
})

const mapDispatchToProps = (dispatch) => ({
  setUser: (e) => dispatch({ type: 'SET_USER', data: e }),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
