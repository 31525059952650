import { request } from 'helpers/Common'

export async function getConfig() {
  const reqStatus = await request('/user/config', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data.data
}

export async function getBalance() {
  const reqStatus = await request('/user/balance', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data.data
}
