const BankStatus = () => {
  return (
    <div className='bank-status'>
      <div className='bank'>
        <div className='bank-container'>
          <img src='/images/green-dot.gif' alt='Green Dot' />
          <img src='/images/banks/bca.webp' alt='BCA Bank' />
        </div>
      </div>
      <div className='bank'>
        <div className='bank-container'>
          <img src='/images/green-dot.gif' alt='Green Dot' />
          <img src='/images/banks/mandiri.webp' alt='Mandiri Bank' />
        </div>
      </div>
      <div className='bank'>
        <div className='bank-container'>
          <img src='/images/green-dot.gif' alt='Green Dot' />
          <img src='/images/banks/bri.webp' alt='BRI Bank' />
        </div>
      </div>
      <div className='bank'>
        <div className='bank-container'>
          <img src='/images/green-dot.gif' alt='Green Dot' />
          <img src='/images/banks/bni.webp' alt='BNI Bank' />
        </div>
      </div>
      <div className='bank'>
        <div className='bank-container'>
          <img src='/images/green-dot.gif' alt='Green Dot' />
          <img src='/images/banks/danamon.webp' alt='Danamon Bank' />
        </div>
      </div>
      <div className='bank'>
        <div className='bank-container'>
          <img src='/images/green-dot.gif' alt='Green Dot' />
          <img src='/images/banks/permata.webp' alt='Permata Bank' />
        </div>
      </div>
      <div className='bank'>
        <div className='bank-container'>
          <img src='/images/green-dot.gif' alt='Green Dot' />
          <img src='/images/banks/panin.webp' alt='Panin Bank' />
        </div>
      </div>
      <div className='bank'>
        <div className='bank-container'>
          <img src='/images/green-dot.gif' alt='Green Dot' />
          <img src='/images/banks/ovo.webp' alt='OVO Bank' />
        </div>
      </div>
      <div className='bank'>
        <div className='bank-container'>
          <img src='/images/green-dot.gif' alt='Green Dot' />
          <img src='/images/banks/gopay.webp' alt='GoPay' />
        </div>
      </div>
      <div className='bank'>
        <div className='bank-container'>
          <img src='/images/green-dot.gif' alt='Green Dot' />
          <img src='/images/banks/dana.webp' alt='DANA Bank' />
        </div>
      </div>
      <div className='bank'>
        <div className='bank-container'>
          <img src='/images/green-dot.gif' alt='Green Dot' />
          <img src='/images/banks/linkaja.webp' alt='LinkAja Bank' />
        </div>
      </div>
      <div className='bank'>
        <div className='bank-container'>
          <img src='/images/green-dot.gif' alt='Green Dot' />
          <img src='/images/banks/bsi.webp' alt='BSI Bank' />
        </div>
      </div>
      <div className='bank'>
        <div className='bank-container'>
          <img src='/images/green-dot.gif' alt='Green Dot' />
          <img src='/images/banks/maybank.webp' alt='Maybank' />
        </div>
      </div>
    </div>
  )
}

export default BankStatus
