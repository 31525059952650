import Carousel from 'components/Caraousel'
import ProgressiveJackpot from 'components/ProgressiveJackpot'

const AgreementScreen = ({ setLoginModal, setRegisterModal }) => {
  return (
    <main>
      <Carousel />
      <ProgressiveJackpot />
      <div className='bg-base-100 pt-0'>
        <div className='bg-base-200 p-3 text-content'>
          <h1 className='mb-2'>Perjanjian Pengguna</h1>
          <p>
            Perlu diingat bahwa judi online adalah kegiatan yang ilegal di Indonesia dan beresiko
            tinggi. Sebagai penyedia layanan website judi yang bertanggung jawab, HG88 tidak dapat
            mendukung atau mempromosikan kegiatan judi online, maka dari itu.
          </p>
          <h2 className='mb-2 mt-2'>
            Hindari bermain judi online demi keamanan dan kesehatan mental Anda.
          </h2>
          <ul>
            <li>Menghindari risiko kehilangan uang dan ketagihan judi.</li>
            <li>Menjaga kesehatan mental dan emosional Anda.</li>
            <li>Melindungi keuangan dan kestabilan kehidupan Anda serta keluarga.</li>
          </ul>
          <h2 className='mb-2 mt-2'>
            Gunakan waktu dan uang Anda untuk kegiatan yang lebih bermanfaat.
          </h2>
          <ul>
            <li>
              Menekuni hobi atau kegiatan produktif lainnya yang dapat meningkatkan kualitas hidup
              Anda.
            </li>
            <li>
              Investasikan waktu dan uang Anda untuk pendidikan, pengembangan diri, atau bisnis yang
              dapat memberikan manfaat jangka panjang.
            </li>
            <li>Memberikan dukungan kepada keluarga atau masyarakat yang membutuhkan.</li>
          </ul>
          <h2 className='mb-2 mt-2'>Bantuan dan Dukungan</h2>
          <p className='mb-2'>
            Jika Anda merasa memiliki kecanduan judi online, atau jika Anda mengalami masalah akibat
            judi online, terdapat beberapa sumber bantuan dan dukungan yang tersedia:
          </p>
          <ul>
            <li>
              Kementerian Sosial Republik Indonesia: Telp 1708,{' '}
              <a href='https://kemensos.go.id/'>Website Kemensos</a>
            </li>
            <li>
              Yayasan Pulih: Telp +62 21 7941485, <a href='https://yayasanpulih.org/'>Website</a>
            </li>
          </ul>
        </div>
      </div>
    </main>
  )
}

export default AgreementScreen
