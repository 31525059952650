import axios from 'axios'
import { BUILDNUMBER, CLIENTVERSION, SERVERURL } from 'constants/var'
import toast from 'react-hot-toast'
import { store } from '../store/store'

export async function request(endpoint, method = 'POST', req = {}, errorToast = false) {
  try {
    const response = await axios({
      method,
      url: `${SERVERURL}${endpoint}`,
      data: req,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': 'id',
        'Client-Version': CLIENTVERSION,
        'Build-Number': BUILDNUMBER,
      },
    })

    if (errorToast && response.data.code > 299) toast.error(response.data.msg || '')

    return {
      error: response.data.code > 299,
      msg: response.data.msg || '',
      data: response.data,
    }
  } catch (error) {
    if (error.response?.status === 401) {
      store.dispatch({ type: 'CLEAR_USER' })
    }

    if (errorToast) toast.error(error.response?.data?.msg || '')

    return {
      error: true,
      msg: error.response?.data?.msg || '',
    }
  }
}

export function formatBalance(balance) {
  const tempBalance = Number(balance).toLocaleString('en-US', { minimumFractionDigits: 0 })
  console.log('tempBalance', `Rp ${tempBalance}`)
  return `Rp ${tempBalance}`
}

export function loadingBar() {
  return (
    <div className='w-full flex items-center justify-center p-2'>
      <span className='refresh-text-label text-xs pr-1'>Memperbarui</span>
      <span className='refresh-loading-bar loading loading-bars loading-xs'></span>
    </div>
  )
}
