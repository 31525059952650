import { Button } from 'react-daisyui'
const LoginRegister = ({ setLoginModal, setRegisterModal }) => {
  return (
    <div className='flex bg-base-100'>
      <Button
        size='md'
        className='flex-1 rounded-none border-2 border-gold bg-goldline text-gold-content highlight-button min-h-10 h-10'
        onClick={setRegisterModal}
      >
        Daftar
      </Button>
      <Button
        size='md'
        className='flex-1 rounded-none border-2 border-secondary-content bg-primary text-primary-content min-h-10 h-10'
        onClick={setLoginModal}
      >
        Masuk
      </Button>
    </div>
  )
}
export default LoginRegister
