import AddBankModal from 'components/AddBankModal'
import BankStatus from 'components/BankStatus'
import ConfirmPopUp from 'components/ConfirmPopUp'
import LoadingView from 'components/LoadingView'
import RebateData from 'components/RebateData'
import UserBalance from 'components/UserBalance'
import WithdrawHistory from 'components/WithdrawHistory'
import {
  getBankOption,
  getClientBank,
  getDepositOption,
  getStatus,
  submitDeposit,
  submitWithdraw,
} from 'controllers/Finance'
import { getBalance } from 'controllers/User'
import { formatBalance, loadingBar } from 'helpers/Common'
import { useEffect, useState } from 'react'
import { Button, Divider, Input, Select } from 'react-daisyui'
import toast from 'react-hot-toast'
import QRCode from 'react-qr-code'
import { useLocation, useNavigate } from 'react-router-dom'
import PullToRefresh from 'react-simple-pull-to-refresh'

const status = {
  cancelled: 'Di Batalkan',
  pending: 'Pending',
  expired: 'Kadaluarsa',
  completed: 'Berhasil',
}

const WallletScreen = ({ user, setLoginModal }) => {
  let navigate = useNavigate()
  const [channel, setChannel] = useState('')
  const [balance, setBalance] = useState(null)
  const [depositList, setDepositList] = useState([])
  const [withdrawData, setWithdrawData] = useState([])
  const [rebate, setRebate] = useState(null)
  const [bankOption, setBankOption] = useState([])
  const [depositOption, setDepositOption] = useState([])
  const [accountName, setAccountName] = useState(null)
  const [myBank, setMyBank] = useState([])
  const [loading, setLoading] = useState(false)

  const [source, setSource] = useState(null)
  const [dest, setDest] = useState(null)
  const [amount, setAmount] = useState(0)
  const [destWithdraw, setDestWithdraw] = useState(null)
  const [withdrawAmount, setWithdrawAmount] = useState(0)

  const [addBankModal, setAddBankModal] = useState(false)
  const [popUp, setPopUp] = useState(null)

  const today = new Date().toISOString().split('T')[0]
  const [dateValue, setDateValue] = useState(today)

  const location = useLocation()
  const { pathname } = location
  const isHome = !['/dompet/isi-saldo', '/dompet/penarikan'].includes(pathname)

  useEffect(() => {
    if (user) updateBalance()
    if (!isHome) {
      queryBankOption()
      getMyBank()
      queryDepositiOption()
    } else {
      queryPaymentStatus()
    }
  }, [user, isHome])

  const queryPaymentStatus = async () => {
    const depositData = await getStatus()

    if (depositData) {
      setWithdrawData(depositData.withdraw)
      setDepositList(depositData.deposit)
      setRebate(depositData.rebate)
    }
  }

  const handleRefresh = async () => {
    updateBalance()
    await queryPaymentStatus()
  }

  function capitalizeWords(str) {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase()
    })
  }

  const handlePayment = async (e) => {
    console.log('handlepayment', e)
    switch (e.mode) {
      case 'link':
        window.open(e.data.data, '_blank')?.focus()
        navigate('/dompet')
        break
      default:
        setPopUp(e)
    }
  }

  const requestDeposit = async () => {
    if (!source || !dest) return toast.error('Pilih metode pengirim dan metode pembayaran')

    const selectedDest = depositOption
      .find((e) => e.list.find((ed) => ed.id === dest))
      .list.find((e) => e.id === dest)
    if (amount < selectedDest.min_amount || amount > selectedDest.max_amount)
      return toast.error('Nominal diluar dari limit yang dibatasi')

    setLoading(true)
    const reqData = {
      source,
      dest,
      amount,
    }
    const depositStatus = await submitDeposit(reqData)

    if (depositStatus) {
      handlePayment(depositStatus)
    }

    setLoading(false)
  }

  const requestWithdraw = async () => {
    if (!destWithdraw) return toast.error('Pilih tujuan penerima')

    const selectedDest = myBank.find((e) => e.id === destWithdraw)
    if (withdrawAmount < selectedDest.minimum_withdraw)
      return toast.error('Nominal diluar dari limit minimun yang dibatasi')

    setLoading(true)
    const reqData = {
      dest: destWithdraw,
      amount: withdrawAmount,
    }
    const withdrawStatus = await submitWithdraw(reqData)

    if (withdrawStatus) {
      toast.success(withdrawStatus.msg)
      navigate('/dompet')
    }

    setLoading(false)
  }

  const queryBankOption = async () => {
    const bankOption = await getBankOption()

    if (bankOption) {
      setBankOption(bankOption.data)
      setAccountName(bankOption.account)
    }
  }

  const updateBalance = async () => {
    const balanceData = await getBalance()

    if (balanceData !== false) {
      setBalance(balanceData)
    }
  }

  const getMyBank = async () => {
    const bankData = await getClientBank()

    if (bankData.length) {
      setSource(bankData[0].id)
      setMyBank(bankData)
    }
  }

  const queryDepositiOption = async () => {
    const depositOption = await getDepositOption()

    if (depositOption.length) {
      setDepositOption(depositOption)
    }
  }

  const changeHistoryDate = (e) => {
    setDateValue(e.target.value)
  }

  if (isHome)
    return (
      <main className='flex flex-1 overflow-y-auto'>
        <div className='bg-base-100 pt-0 flex flex-col flex-1 overflow-y-auto'>
          <UserBalance
            balance={balance}
            last_login={user?.last_login}
            updateBalance={updateBalance}
          />
          <div className='bg-base-200 p-3'>
            <div className='flex gap-2'>
              <Button
                tag='a'
                className={`flex-1 border ${pathname === '/dompet' ? 'border-primary-content' : 'border-gold'}`}
                color='accent'
                animation={false}
                shape='square'
                size='md'
                active={pathname === '/dompet'}
                href='/dompet'
                startIcon={
                  <svg
                    className={`w-8 h-8`}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
                    <g id='SVGRepo_iconCarrier'>
                      {' '}
                      <path
                        d='M12 8V12L14.5 14.5'
                        stroke='currentcolor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      ></path>{' '}
                      <path
                        d='M5.60423 5.60423L5.0739 5.0739V5.0739L5.60423 5.60423ZM4.33785 6.87061L3.58786 6.87438C3.58992 7.28564 3.92281 7.61853 4.33408 7.6206L4.33785 6.87061ZM6.87963 7.63339C7.29384 7.63547 7.63131 7.30138 7.63339 6.88717C7.63547 6.47296 7.30138 6.13549 6.88717 6.13341L6.87963 7.63339ZM5.07505 4.32129C5.07296 3.90708 4.7355 3.57298 4.32129 3.57506C3.90708 3.57715 3.57298 3.91462 3.57507 4.32882L5.07505 4.32129ZM3.75 12C3.75 11.5858 3.41421 11.25 3 11.25C2.58579 11.25 2.25 11.5858 2.25 12H3.75ZM16.8755 20.4452C17.2341 20.2378 17.3566 19.779 17.1492 19.4204C16.9418 19.0619 16.483 18.9393 16.1245 19.1468L16.8755 20.4452ZM19.1468 16.1245C18.9393 16.483 19.0619 16.9418 19.4204 17.1492C19.779 17.3566 20.2378 17.2341 20.4452 16.8755L19.1468 16.1245ZM5.14033 5.07126C4.84598 5.36269 4.84361 5.83756 5.13505 6.13191C5.42648 6.42626 5.90134 6.42862 6.19569 6.13719L5.14033 5.07126ZM18.8623 5.13786C15.0421 1.31766 8.86882 1.27898 5.0739 5.0739L6.13456 6.13456C9.33366 2.93545 14.5572 2.95404 17.8017 6.19852L18.8623 5.13786ZM5.0739 5.0739L3.80752 6.34028L4.86818 7.40094L6.13456 6.13456L5.0739 5.0739ZM4.33408 7.6206L6.87963 7.63339L6.88717 6.13341L4.34162 6.12062L4.33408 7.6206ZM5.08784 6.86684L5.07505 4.32129L3.57507 4.32882L3.58786 6.87438L5.08784 6.86684ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM16.1245 19.1468C14.9118 19.8483 13.5039 20.25 12 20.25V21.75C13.7747 21.75 15.4407 21.2752 16.8755 20.4452L16.1245 19.1468ZM20.25 12C20.25 13.5039 19.8483 14.9118 19.1468 16.1245L20.4452 16.8755C21.2752 15.4407 21.75 13.7747 21.75 12H20.25ZM6.19569 6.13719C7.68707 4.66059 9.73646 3.75 12 3.75V2.25C9.32542 2.25 6.90113 3.32791 5.14033 5.07126L6.19569 6.13719Z'
                        fill='currentcolor'
                      ></path>{' '}
                    </g>
                  </svg>
                }
              ></Button>
              <Button
                tag='a'
                className={`flex-1 border ${pathname === '/dompet/isi-saldo' ? 'border-primary-content' : 'border-gold'}`}
                color='accent'
                animation={false}
                shape='square'
                size='md'
                active={pathname === '/dompet/isi-saldo'}
                onClick={() => {
                  if (!user) setLoginModal(true)
                }}
                href={user && '/dompet/isi-saldo'}
                startIcon={
                  <svg
                    className={`w-8 h-8`}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
                    <g id='SVGRepo_iconCarrier'>
                      {' '}
                      <path
                        d='M2 12.0002C2 7.28611 2 4.92909 3.46447 3.46462C4.70529 2.2238 6.58687 2.03431 10 2.00537M22 12.0002C22 7.28611 22 4.92909 20.5355 3.46462C19.2947 2.2238 17.4131 2.03431 14 2.00537'
                        stroke='currentcolor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                      ></path>{' '}
                      <path
                        d='M10 22C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22'
                        stroke='currentcolor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                      ></path>{' '}
                      <path
                        d='M12 11L12 17M12 17L14.5 14.5M12 17L9.5 14.5'
                        stroke='currentcolor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      ></path>{' '}
                    </g>
                  </svg>
                }
              >
                Deposit
              </Button>
              <Button
                tag='a'
                className={`flex-1 border ${pathname === '/dompet/penarikan' ? 'border-primary-content' : 'border-gold'}`}
                color='accent'
                animation={false}
                shape='square'
                size='md'
                onClick={() => {
                  if (!user) setLoginModal(true)
                }}
                href={user && '/dompet/penarikan'}
                active={pathname === '/dompet/penarikan'}
                startIcon={
                  <svg
                    className={`w-8 h-8`}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
                    <g id='SVGRepo_iconCarrier'>
                      {' '}
                      <path
                        d='M2 11.9997C2 7.28562 2 4.9286 3.46447 3.46413C4.70529 2.22331 6.58687 2.03382 10 2.00488M22 11.9997C22 7.28562 22 4.9286 20.5355 3.46413C19.2947 2.22331 17.4131 2.03382 14 2.00488'
                        stroke='currentcolor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                      ></path>{' '}
                      <path
                        d='M10 22C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22'
                        stroke='currentcolor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                      ></path>{' '}
                      <path
                        d='M12 17L12 11M12 11L14.5 13.5M12 11L9.5 13.5'
                        stroke='currentcolor'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      ></path>{' '}
                    </g>
                  </svg>
                }
              >
                WD
              </Button>
            </div>
          </div>
          <div className='bg-base-100 py-3 flex flex-col overflow-x-auto'>
            <PullToRefresh
              onRefresh={handleRefresh}
              refreshingContent={loadingBar()}
              isPullable={!!user}
            >
              <RebateData data={rebate} />
              <WithdrawHistory data={withdrawData} />
              {user && (
                <div className='flex mx-3 my-2 justify-between'>
                  <h1 className='font-semibold'>Riwayat Transaksi Terakhir</h1>
                  {/* <Input
                    className=' w-1/3 text-primary-content'
                    size='xs'
                    type='date'
                    value={dateValue}
                    onChange={changeHistoryDate}
                  /> */}
                </div>
              )}
              {!user && (
                <div onClick={() => setLoginModal(true)} className='mx-3'>
                  Silahkan Masuk Akun
                </div>
              )}
              {depositList.map((e, index) => (
                <div
                  key={index}
                  className='bg-base-200 flex flex-col mx-3 mb-3 rounded-md p-3 border border-base-300 text-primary-content'
                >
                  <div className='flex flex-row justify-between'>
                    <span>{e.date}</span>
                    <span>{status[e.status]}</span>
                  </div>
                  <div className='flex flex-row justify-between'>
                    <span>Sumber Dana</span>
                    <span>{e.source.bank_name}</span>
                  </div>
                  <hr className='mb-3 mt-1' />
                  <div className='flex flex-row justify-between'>
                    <span className=' text-lg'>Informasi Pembayaran</span>
                  </div>
                  <div className='flex flex-row justify-between'>
                    <span>Metode</span>
                    <span>{e.dest.title}</span>
                  </div>
                  <div className='flex flex-row justify-between'>
                    <span>Biaya Admin</span>
                    <span>{formatBalance(e.user_fee)}</span>
                  </div>
                  <div className='flex flex-row justify-between'>
                    <span>Promo</span>
                    <span>{formatBalance(e.extra_amount)}</span>
                  </div>
                  <hr />
                  <div className='flex flex-row justify-between'>
                    <span>TOTAL</span>
                    <span>{formatBalance(e.total_amount)}</span>
                  </div>
                  {e.status === 'pending' && (
                    <div className='flex flex-row justify-between'>
                      <Button
                        size='sm'
                        className={`w-full mt-3 ${loading ? '' : 'bg-goldline'} text-gold-content border-gold border-2`}
                        onClick={() => handlePayment(e.dest)}
                        loading={loading}
                        disabled={loading}
                      >
                        BAYAR
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            </PullToRefresh>
            {/* <Table size='xs' zebra={true}>
              <Table.Head>
                <span />
                <span>Tanggal</span>
                <span>Transaksi</span>
                <span>Nominal</span>
                <span>Status</span>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <span>1</span>
                  <span>24/03</span>
                  <span>Deposit</span>
                  <span>+Rp 50.000</span>
                  <span>Berhasil</span>
                </Table.Row>
              </Table.Body>
            </Table> */}
          </div>
        </div>
        <ConfirmPopUp
          popUp={popUp}
          close={() => {
            setPopUp(false)
          }}
        >
          {!!popUp && (
            <div className='flex flex-1 flex-col justify-start'>
              {popUp.mode === 'qr' && (
                <div style={{ height: 'auto', margin: '0 auto', width: '100%' }}>
                  <QRCode
                    size={256}
                    bgColor='#000000'
                    fgColor='#FFFFFF'
                    style={{ height: 'auto', width: '100%' }}
                    value={popUp.data.data}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              )}
              {['bank', 'va'].includes(popUp.mode) &&
                Object.keys(popUp.data).map((key) => (
                  <div className='flex justify-between' key={key}>
                    <div>{capitalizeWords(key.split('_').join(' '))}</div>
                    <div className='text-primary-content'>{popUp.data[key]}</div>
                  </div>
                ))}
              {/* <Button size='xs' color='success'>
                Salin
              </Button> */}
            </div>
          )}
        </ConfirmPopUp>
      </main>
    )

  if (pathname === '/dompet/isi-saldo')
    return (
      <main>
        {loading && <LoadingView />}
        <div className='bg-base-100 pt-0'>
          <UserBalance
            balance={balance}
            last_login={user?.last_login}
            updateBalance={updateBalance}
          />
          <div className='bg-base-200 p-3'>
            <div className='flex flex-row justify-between'>
              {/* <h1 className='mb-2 text-primary-content'>Metode Deposit</h1> */}
              <Button
                tag='a'
                size='xs'
                color='info'
                href='/dompet'
                startIcon={
                  <svg
                    className='w-5 h-5'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
                    <g id='SVGRepo_iconCarrier'>
                      {' '}
                      <path
                        d='M4 10L3.29289 10.7071L2.58579 10L3.29289 9.29289L4 10ZM21 18C21 18.5523 20.5523 19 20 19C19.4477 19 19 18.5523 19 18L21 18ZM8.29289 15.7071L3.29289 10.7071L4.70711 9.29289L9.70711 14.2929L8.29289 15.7071ZM3.29289 9.29289L8.29289 4.29289L9.70711 5.70711L4.70711 10.7071L3.29289 9.29289ZM4 9L14 9L14 11L4 11L4 9ZM21 16L21 18L19 18L19 16L21 16ZM14 9C17.866 9 21 12.134 21 16L19 16C19 13.2386 16.7614 11 14 11L14 9Z'
                        fill='currentcolor'
                      ></path>{' '}
                    </g>
                  </svg>
                }
              >
                Kembali
              </Button>
            </div>
            {/* <div className='flex gap-1'>
              <Button
                className={`flex-1 ${selectedMethod === 'bank' && 'border-primary-content'}`}
                color='accent'
                animation={false}
                shape='square'
                size='md'
                active={selectedMethod === 'bank'}
                onClick={() => setSeletecMethod('bank')}
                startIcon={
                  <svg
                    className={`w-8 h-8`}
                    fill='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
                    <g id='SVGRepo_iconCarrier'>
                      <path d='M3,22H21a1,1,0,0,0,1-1V17a1,1,0,0,0-1-1H20V11h1a1,1,0,0,0,1-1V7a1,1,0,0,0-.594-.914l-9-4a1,1,0,0,0-.812,0l-9,4A1,1,0,0,0,2,7v3a1,1,0,0,0,1,1H4v5H3a1,1,0,0,0-1,1v4A1,1,0,0,0,3,22ZM4,7.65l8-3.556L20,7.65V9H4ZM18,11v5H15.333V11Zm-4.667,0v5H10.667V11ZM8.667,11v5H6V11ZM4,18H20v2H4ZM14,7a1,1,0,0,1-1,1H11a1,1,0,0,1,0-2h2A1,1,0,0,1,14,7Z'></path>
                    </g>
                  </svg>
                }
              >
                Bank
              </Button>
              <Button
                className={`flex-1 ${selectedMethod === 'e-wallet' && 'border-primary-content'}`}
                color='accent'
                animation={false}
                shape='square'
                size='md'
                active={selectedMethod === 'e-wallet'}
                onClick={() => setSeletecMethod('e-wallet')}
                startIcon={
                  <svg
                    className={`w-8 h-8`}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
                    <g id='SVGRepo_iconCarrier'>
                      <path
                        d='M16.5008 14.1502H16.5098M19 4.00098H6.2C5.0799 4.00098 4.51984 4.00098 4.09202 4.21896C3.71569 4.41071 3.40973 4.71667 3.21799 5.093C3 5.52082 3 6.08087 3 7.20098V16.801C3 17.9211 3 18.4811 3.21799 18.909C3.40973 19.2853 3.71569 19.5912 4.09202 19.783C4.51984 20.001 5.07989 20.001 6.2 20.001H17.8C18.9201 20.001 19.4802 20.001 19.908 19.783C20.2843 19.5912 20.5903 19.2853 20.782 18.909C21 18.4811 21 17.9211 21 16.801V11.201C21 10.0809 21 9.52082 20.782 9.093C20.5903 8.71667 20.2843 8.41071 19.908 8.21896C19.4802 8.00098 18.9201 8.00098 17.8 8.00098H7M16.9508 14.1502C16.9508 14.3987 16.7493 14.6002 16.5008 14.6002C16.2523 14.6002 16.0508 14.3987 16.0508 14.1502C16.0508 13.9017 16.2523 13.7002 16.5008 13.7002C16.7493 13.7002 16.9508 13.9017 16.9508 14.1502Z'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      ></path>
                    </g>
                  </svg>
                }
              >
                E-Wallet/QRIS
              </Button>
            </div> */}
            <div className='row-container'>
              <div className='flex flex-row justify-between items-center text-primary-content'>
                <Divider color='base' className='flex-1 text-primary-content' start={true}>
                  Rekening Pengirim
                </Divider>
                <Button
                  size='xs'
                  color='info'
                  onClick={() => setAddBankModal(true)}
                  endIcon={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-5 h-5'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
                      />
                    </svg>
                  }
                >
                  Tambah Rekening
                </Button>
              </div>
              <div className='flex justify-between'>
                {/* <div>Nama Pengirim</div> */}
                <Select
                  color='neutral'
                  className='text-center text-primary-content w-full'
                  size='md'
                  bordered={false}
                  onChange={(event) => setSource(event.target.value)}
                >
                  <option value={''}>Pilih Metode Pengirim</option>
                  {myBank.map((item, index) => (
                    <option
                      key={`S${index}`}
                      value={item.id}
                    >{`[${item.bank_code}] ${item.account_name} ${item.account_number}`}</option>
                  ))}
                </Select>
              </div>
              <Divider color='base' className='text-primary-content' start={true}>
                Metode Pembayaran
              </Divider>
              <div className='flex justify-between'>
                <Select
                  color='neutral'
                  className='text-center text-primary-content w-full'
                  size='md'
                  bordered={false}
                  onChange={(event) => {
                    if (!event.target.value) return
                    setDest(event.target.value)
                    const minAmount = depositOption
                      .find((e) => e.list.find((ed) => ed.id === event.target.value))
                      .list.find((e) => e.id === event.target.value).min_amount
                    setAmount(minAmount)
                  }}
                >
                  <option value={''}>Pilih Metode Pembayaran</option>
                  {depositOption.map((e, index) => {
                    return (
                      <>
                        <optgroup key={`D${index}`}>{e.label}</optgroup>
                        {e.list.map((ed, index2) => {
                          return (
                            <option
                              key={`DA${index2}`}
                              value={ed.id}
                              disabled={ed.status !== 'normal'}
                            >
                              {ed.title}
                            </option>
                          )
                        })}
                      </>
                    )
                  })}
                </Select>
              </div>
              <div>
                <p className='text-success'>
                  Nomor pembayaran / QR akan muncul setelah "Ambil Kode Pembayaran"
                </p>
              </div>
              <div className='flex flex-col'>
                <Input
                  color='primary'
                  className='w-full mt-3 mb-0 text-primary-content text-center'
                  value={formatBalance(amount)}
                  onChange={(e) => {
                    setAmount(e.target.value.replace(/[^\d.]/g, ''))
                  }}
                  placeholder='Jumlah Deposit'
                />
              </div>
              <p className='text-danger'>Masukkan 10.000 = Rp 10.000 = 1 Banding 1</p>
              <Button
                className={`w-full mt-3 ${loading ? '' : 'bg-goldline'} text-gold-content border-gold border-2`}
                onClick={requestDeposit}
                loading={loading}
                disabled={loading}
              >
                AMBIL KODE PEMBAYARAN
              </Button>
              <ConfirmPopUp
                popUp={popUp}
                close={() => {
                  setPopUp(false)
                  navigate('/dompet')
                }}
              >
                {!!popUp && (
                  <div className='flex flex-1 flex-col justify-start'>
                    {popUp.mode === 'qr' && (
                      <div style={{ height: 'auto', margin: '0 auto', width: '100%' }}>
                        <QRCode
                          size={256}
                          bgColor='#000000'
                          fgColor='#FFFFFF'
                          style={{ height: 'auto', width: '100%' }}
                          value={popUp.data.data}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                    )}
                    {['bank', 'va'].includes(popUp.mode) &&
                      Object.keys(popUp.data).map((key) => (
                        <div className='flex justify-between' key={key}>
                          <div>{capitalizeWords(key.split('_').join(' '))}</div>
                          <div className='text-primary-content'>{popUp.data[key]}</div>
                        </div>
                      ))}
                    {/* <Button size='xs' color='success'>
                Salin
              </Button> */}
                  </div>
                )}
              </ConfirmPopUp>
            </div>
          </div>
          <div className='bg-base-200 p-3 text-content text-primary-content'>
            <h1 className='mb-2'>Peraturan Deposit</h1>
            <p>Deposit sesuai dengan prosedur akan di proses secara instan.</p>
            <ul>
              <li>Minimal Deposit Rp 10.000</li>
              <li>Deposit menggunakan selain dari rekening yang didaftarkan tidak diperbolehkan</li>
              <li>Menjaga kesehatan mental dan emosional Anda</li>
              <li>Melindungi keuangan dan kestabilan kehidupan Anda serta keluarga</li>
            </ul>
            <h1 className='mb-2 mt-2'>Bantuan dan Dukungan</h1>
            <p className='mb-2'>
              Jika anda mengalami kesulitan maupun kendala perihal deposit, silahkan hubungi layanan
              bantuan kami, kami siap membantu para member 24jam/7hari/1tahun.
            </p>
          </div>
          <div className='bg-base-300 p-3'>
            <BankStatus />
          </div>
        </div>
        <AddBankModal
          accountName={accountName}
          bankOption={bankOption}
          addBankModal={addBankModal}
          setAddBankModal={setAddBankModal}
          myBank={myBank}
          setMyBank={setMyBank}
        />
      </main>
    )

  if (pathname === '/dompet/penarikan')
    return (
      <main>
        <div className='bg-base-100 pt-0'>
          <UserBalance
            balance={balance}
            last_login={user?.last_login}
            updateBalance={updateBalance}
          />
          <div className='bg-base-200 p-3'>
            <div className='flex flex-row justify-between'>
              <h1 className='mb-2 text-primary-content'>Penarikan Saldo</h1>
              <Button
                tag='a'
                size='xs'
                color='info'
                href='/dompet'
                startIcon={
                  <svg
                    className='w-5 h-5'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
                    <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
                    <g id='SVGRepo_iconCarrier'>
                      {' '}
                      <path
                        d='M4 10L3.29289 10.7071L2.58579 10L3.29289 9.29289L4 10ZM21 18C21 18.5523 20.5523 19 20 19C19.4477 19 19 18.5523 19 18L21 18ZM8.29289 15.7071L3.29289 10.7071L4.70711 9.29289L9.70711 14.2929L8.29289 15.7071ZM3.29289 9.29289L8.29289 4.29289L9.70711 5.70711L4.70711 10.7071L3.29289 9.29289ZM4 9L14 9L14 11L4 11L4 9ZM21 16L21 18L19 18L19 16L21 16ZM14 9C17.866 9 21 12.134 21 16L19 16C19 13.2386 16.7614 11 14 11L14 9Z'
                        fill='currentcolor'
                      ></path>{' '}
                    </g>
                  </svg>
                }
              >
                Kembali
              </Button>
            </div>
            <div className='row-container'>
              <Divider color='base' className='text-primary-content' start={true}>
                Rekening Penerima
              </Divider>
              <div className='flex justify-between'>
                <div>Nama Pemilik</div>
                <div className='text-primary-content'>
                  {destWithdraw ? myBank.find((e) => e.id === destWithdraw).account_name : '-'}
                </div>
              </div>
              <div className='flex justify-between'>
                <div>Nomor Rekening</div>
                <div className='text-primary-content'>
                  {destWithdraw ? myBank.find((e) => e.id === destWithdraw).account_number : '-'}
                </div>
              </div>
              <div className='flex justify-between mt-3'>
                <Select
                  color='neutral'
                  className='text-center text-primary-content w-full'
                  size='md'
                  bordered={false}
                  onChange={(event) => setDestWithdraw(event.target.value)}
                >
                  <option value={''}>Pilih Tujuan Penarikan</option>
                  {myBank.map((item, index) => (
                    <option
                      key={`S${index}`}
                      value={item.id}
                    >{`[${item.bank_code}] ${item.account_name} ${item.account_number}`}</option>
                  ))}
                </Select>
              </div>
              <div>
                <p className='text-success'>
                  Pastikan data anda benar, penarikan tidak bisa dibatalkan setelah form di kirim
                </p>
              </div>
              <div className='flex flex-col'>
                <Input
                  color='primary'
                  className='w-full mt-3 mb-0 text-primary-content text-center'
                  value={formatBalance(withdrawAmount)}
                  onChange={(e) => {
                    setWithdrawAmount(Number(e.target.value.replace(/[^\d.]/g, '')))
                  }}
                  placeholder='Jumlah Penarikan'
                />
              </div>
              <Button
                className={`w-full mt-3 ${loading ? '' : 'bg-goldline'} text-gold-content border-gold border-2`}
                onClick={requestWithdraw}
                loading={loading}
                disabled={loading}
              >
                Proses Penarikan
              </Button>
            </div>
          </div>
          <div className='bg-base-300 p-3'>
            <BankStatus />
          </div>
        </div>
        <AddBankModal
          bankOption={bankOption}
          addBankModal={addBankModal}
          setAddBankModal={setAddBankModal}
          myBank={myBank}
          setMyBank={setMyBank}
        />
      </main>
    )
}

export default WallletScreen
