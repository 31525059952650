import { logout } from 'controllers/Auth'
import { useCallback, useState } from 'react'
import { Badge, Button, Drawer, Dropdown, Menu, Navbar } from 'react-daisyui'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

const NavTop = ({ user, clearUser }) => {
  const location = useLocation()
  const { pathname } = location
  const [visible, setVisible] = useState(false)
  const toggleVisible = useCallback(() => {
    setVisible((visible) => !visible)
  }, [])
  const [loading, setLoading] = useState(false)

  if (
    [
      '/referral',
      '/dompet',
      '/layanan-pelanggan',
      '/dompet/isi-saldo',
      '/dompet/penarikan',
    ].includes(pathname)
  )
    return

  const handleLogout = async () => {
    setLoading(true)
    const loginStatus = await logout()

    if (loginStatus) {
      toggleVisible()
      clearUser()
    }

    setLoading(false)
  }

  return (
    <Drawer
      open={visible}
      onClickOverlay={loading ? null : toggleVisible}
      side={
        <div className='drawer-container'>
          <div className='p-3 pl-5'>
            <h1>Salam Gacor!</h1>
            <h1>{user && user.username}</h1>
          </div>
          <Menu>
            {pathname !== '/' && (
              <Menu.Item>
                <Link to='/'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='w-6 h-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6'
                    />
                  </svg>
                  Beranda
                </Link>
              </Menu.Item>
            )}
            <Menu.Item>
              <Link to='/dompet/isi-saldo' onClick={toggleVisible}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3'
                  />
                </svg>
                Deposit
                <Badge size='sm' color='error'>
                  +100%
                </Badge>
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to='/dompet/penarikan' onClick={toggleVisible}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5'
                  />
                </svg>
                Withdraw
                <Badge color='warning' size='sm'>
                  1Detik
                </Badge>
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to='/dompet' onClick={toggleVisible}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z'
                  />
                </svg>
                Transaksi
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to='/referral'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25'
                  />
                </svg>
                Referral
                <Badge color='info' size='sm'>
                  HOT
                </Badge>
              </Link>
            </Menu.Item>
            {/* <Menu.Item>
              <Link to='/'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15.362 5.214A8.252 8.252 0 0 1 12 21 8.25 8.25 0 0 1 6.038 7.047 8.287 8.287 0 0 0 9 9.601a8.983 8.983 0 0 1 3.361-6.867 8.21 8.21 0 0 0 3 2.48Z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M12 18a3.75 3.75 0 0 0 .495-7.468 5.99 5.99 0 0 0-1.925 3.547 5.975 5.975 0 0 1-2.133-1.001A3.75 3.75 0 0 0 12 18Z'
                  />
                </svg>
                Hiburan 18+
              </Link>
            </Menu.Item> */}
            {user && (
              <Menu.Item>
                <Button onClick={handleLogout} loading={loading} disabled={loading}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m0-3-3-3m0 0-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75'
                    />
                  </svg>
                  Keluar Akun
                </Button>
              </Menu.Item>
            )}
          </Menu>
        </div>
      }
    >
      <Navbar className='bg-base-100 pb-1 pt-1 min-h-max'>
        <Navbar.Start>
          <span tag='a' color='ghost' className='normal-case text-xl pl-5'>
            HG88
          </span>
          <Dropdown></Dropdown>
        </Navbar.Start>
        <Navbar.End className='navbar-end'>
          <Button
            className=' min-h-10 h-10'
            tag='label'
            color='ghost'
            shape='circle'
            onClick={toggleVisible}
            tabIndex={0}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25'
              />
            </svg>
          </Button>
        </Navbar.End>
      </Navbar>
    </Drawer>
  )
}

const mapDispatchToProps = (dispatch) => ({
  clearUser: () => dispatch({ type: 'CLEAR_USER' }),
})

export default connect(null, mapDispatchToProps)(NavTop)
