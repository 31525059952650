import { request } from 'helpers/Common'
import toast from 'react-hot-toast'

export async function register(e) {
  const reqStatus = await request('/user/register', 'POST', e)

  if (reqStatus.error) {
    toast.error(reqStatus.msg)
    return false
  }
  toast.success(reqStatus.data.msg)

  return reqStatus.data.user
}

export async function login(e) {
  const reqStatus = await request('/user/verify-account', 'POST', e)

  if (reqStatus.error) {
    toast.error(reqStatus.msg)
    return false
  }
  toast.success(reqStatus.data.msg)

  return reqStatus.data.user
}

export async function logout() {
  const reqStatus = await request('/user/logout', 'POST')

  if (reqStatus.error) {
    toast.error(reqStatus.msg)
    return false
  }
  toast.success(reqStatus.data.msg)

  return true
}
