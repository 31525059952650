import ConfirmPopUp from 'components/ConfirmPopUp'
import PopUp from 'components/PopUp'
import { getPromotion, queryPromotionStatus, takePromotion } from 'controllers/Promotion'
import { formatBalance, loadingBar } from 'helpers/Common'
import { useCallback, useEffect, useState } from 'react'
import { Button, Card, Input, Table } from 'react-daisyui'
import toast from 'react-hot-toast'
import PullToRefresh from 'react-simple-pull-to-refresh'

const status = {
  in_progress: 'Berlangsung',
  cancelled: 'Dibatalkan',
  completed: 'Tercapai',
}

const PromotionScreen = ({ user }) => {
  const [promotionData, setPromotionData] = useState([])
  const [promotionStatus, setPromotionStatus] = useState(null)
  const [selectedPromo, setSelectedPromo] = useState(null)
  const [confirmPromo, setConfirmPromo] = useState(false)
  const [promotionAmount, setPromotionAmount] = useState(0)
  const [loading, setLoading] = useState(false)

  const getPromotionData = async () => {
    const promotion = await getPromotion()
    if (promotion) {
      setPromotionData(promotion)
    }
  }

  const getPromotionStatus = useCallback(async () => {
    const promotion = await queryPromotionStatus()
    if (promotion) {
      setPromotionStatus(promotion)
    }
  }, [])

  const handleRefresh = async () => {
    await getPromotionData()
  }

  const takePromo = async (id) => {
    if (promotionAmount < selectedPromo.min_amount || promotionAmount > selectedPromo.max_amount)
      return toast.error('Nominal diluar dari limit yang dibatasi')

    setLoading(true)
    setConfirmPromo(false)

    const status = await takePromotion({ id, amount: promotionAmount })
    if (status) {
      getPromotionStatus()
      setSelectedPromo(null)
    }

    setLoading(false)
    setPromotionAmount(0)
  }

  useEffect(() => {
    getPromotionData()

    if (user) getPromotionStatus()
  }, [user, getPromotionStatus])

  return (
    <main className='flex flex-1'>
      <div className='bg-base-100 pt-0 flex flex-col'>
        {promotionStatus && (
          <div className='bg-base-300 text-content'>
            <h1 className='mb-2 p-3 pb-0'>{promotionStatus.title}</h1>
            <Table>
              <Table.Body>
                <Table.Row>
                  <span>{promotionStatus.name}</span>
                  <span>{status[promotionStatus.status]}</span>
                </Table.Row>
                <Table.Row>
                  <span>Total Bonus</span>
                  <span>{formatBalance(promotionStatus.total_bonus)}</span>
                </Table.Row>
                <Table.Row>
                  <span>TurnOver Bet</span>
                  <span>
                    {promotionStatus.to_progress}/{promotionStatus.to_required}
                  </span>
                </Table.Row>
                <Table.Row>
                  <span>TurnOver Win</span>
                  <span>
                    {promotionStatus.wo_progress}/{promotionStatus.wo_required}
                  </span>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        )}
        <div className='flex flex-col flex-1 bg-base-200 text-content'>
          <h1 className='mb-2 p-3 pb-0'>Promosi Terbaru</h1>
          <div className='bg-base-100 flex-1 flex flex-row overflow-hidden'>
            <PullToRefresh
              onRefresh={handleRefresh}
              refreshingContent={loadingBar()}
              className='w-full overflow-auto px-2 pt-2'
            >
              {promotionData.map((e, index) => {
                return (
                  <Card
                    key={index}
                    className='w-full p-1 inline-block text-nowrap'
                    onClick={() => setSelectedPromo(e)}
                  >
                    <Card.Image className='w-full' src={e.img_url} alt={e.en_name} loading='lazy' />
                    <span className='py-2 px-2 text-xs text-left bg-base-200 border border-gold text-primary-content text-flow'>
                      <div className='flex flex-row justify-between'>
                        <span>
                          {e.name}
                          <br />
                          {e.description}
                        </span>
                        <Button
                          size='sm'
                          className='border border-gold bg-goldline text-gold-content highlight-button rounded-sm'
                        >
                          {user ? 'AMBIL PROMO' : 'RINCIAN'}
                        </Button>
                      </div>
                    </span>
                  </Card>
                )
              })}
            </PullToRefresh>
          </div>
        </div>
      </div>
      <PopUp
        open={!!selectedPromo}
        close={true}
        handleClose={() => !loading && setSelectedPromo(null)}
      >
        {selectedPromo && (
          <div>
            <img
              className='w-full'
              src={selectedPromo.img_url}
              alt={selectedPromo.name}
              loading='lazy'
            />
            <span className='py-2 px-2 text-xs text-left bg-base-200 border border-gold text-primary-content text-flow'>
              <div className='flex flex-row justify-between'>
                <span>
                  {selectedPromo.name}
                  <br />
                  {selectedPromo.description}
                </span>
                {!!user && (
                  <Button
                    size='sm'
                    className='border border-gold bg-goldline text-gold-content highlight-button rounded-sm'
                    onClick={() => !loading && setConfirmPromo(true)}
                  >
                    {loading ? <span className='loading loading-spinner'></span> : 'AMBIL PROMO'}
                  </Button>
                )}
              </div>
            </span>
            <Table className='bg-base-200 rounded-none'>
              <Table.Body>
                <Table.Row>
                  <span>Bonus</span>
                  <span>{selectedPromo.bonus}%</span>
                </Table.Row>
                <Table.Row>
                  <span>Kategori</span>
                  <span>{selectedPromo.game_type}</span>
                </Table.Row>
                <Table.Row>
                  <span>Provider</span>
                  <span>{selectedPromo.provider_list}</span>
                </Table.Row>
                <Table.Row>
                  <span>Batas Nominal</span>
                  <span>
                    {formatBalance(selectedPromo.min_amount)}
                    {' - '}
                    {formatBalance(selectedPromo.max_amount)}
                  </span>
                </Table.Row>
                <Table.Row>
                  <span>TurnOver Bet</span>
                  <span>{selectedPromo.to_required}X</span>
                </Table.Row>
                <Table.Row>
                  <span>TurnOver Win</span>
                  <span>{selectedPromo.wo_required}X</span>
                </Table.Row>
              </Table.Body>
            </Table>
            <div className='bg-base-200 mt-3 p-3'>
              <span>{selectedPromo.rules}</span>
            </div>
          </div>
        )}
      </PopUp>
      <ConfirmPopUp
        popUp={confirmPromo ? { title: 'Konfirmasi' } : null}
        confirm={() => takePromo(selectedPromo.id)}
        close={() => setConfirmPromo(null)}
      >
        <span>Apakah Anda yakin ingin mengambil promosi tersebut? Masukkan jumlah claim</span>
        <Input
          color='primary'
          className='w-full mt-3 mb-0 text-primary-content text-center'
          value={formatBalance(promotionAmount)}
          onChange={(e) => {
            setPromotionAmount(Number(e.target.value.replace(/[^\d.]/g, '')))
          }}
          placeholder='Nominal Claim'
        />
      </ConfirmPopUp>
    </main>
  )
}

export default PromotionScreen
