import { Button, Modal } from 'react-daisyui'

const ConfirmPopUp = ({ popUp, close, children, confirm }) => {
  if (!popUp) return
  return (
    <Modal.Legacy open={!!popUp} className='bg-base-200'>
      <Modal.Header className='font-bold'>{popUp.title}</Modal.Header>
      <Modal.Body>{children && children}</Modal.Body>
      <Modal.Actions>
        {!!confirm && (
          <Button
            size='sm'
            className='border border-gold bg-goldline text-gold-content highlight-button rounded-sm'
            onClick={confirm}
          >
            Ya
          </Button>
        )}
        <Button size='sm' onClick={close}>
          Tutup
        </Button>
      </Modal.Actions>
    </Modal.Legacy>
  )
}

export default ConfirmPopUp
