import { Button, Modal } from 'react-daisyui'

const PopUp = ({ open, handleClose, close, children, header, footer }) => {
  if (!open) return
  return (
    <Modal.Legacy open={open} onClickBackdrop={handleClose} className='bg-base-100'>
      {close && (
        <Button
          size='sm'
          color='ghost'
          shape='circle'
          className='absolute right-2 top-2'
          onClick={handleClose}
        >
          x
        </Button>
      )}
      {header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Body>{children && children}</Modal.Body>
      {footer && <Modal.Actions>{footer}</Modal.Actions>}
    </Modal.Legacy>
  )
}

export default PopUp
