import { useState } from 'react'
import LoadingView from './LoadingView'
function WebView({ url }) {
  const [isLoading, setIsLoading] = useState(true)

  const handleLoad = () => {
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }

  return (
    <div className='flex-1 z-10 top-0 flex flex-col'>
      {isLoading && <LoadingView />}
      <iframe title='Web View' src={url} className='flex-1' onLoad={handleLoad} />
    </div>
  )
}

export default WebView
