import { request } from 'helpers/Common'
import toast from 'react-hot-toast'

export async function getPromotion() {
  const reqStatus = await request('/user/promotion', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data.data
}

export async function queryPromotionStatus() {
  const reqStatus = await request('/user/promotion/status', 'GET')
  if (reqStatus.error) return false

  return reqStatus.data.data
}

export async function takePromotion(e) {
  const reqStatus = await request('/user/promotion', 'POST', e)

  if (reqStatus.error) {
    toast.error(reqStatus.msg)
    return false
  }
  toast.success(reqStatus.data.msg)

  return true
}
